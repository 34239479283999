import React, { useContext } from "react"

import TicketFormContext from "../../context/TicketFormContext"

const RecommendToolsH12 = props => {
  const context = useContext(TicketFormContext);
    return(
      <React.Fragment>
      { context.macro_step == 2 &&
        <p>In order to better understand <strong>where requests are spending their time</strong> you'll need to use a performance metrics tool, such as <a href="https://devcenter.heroku.com/articles/newrelic" target="_blank" className="hk-link">New Relic</a> or <a href="https://devcenter.heroku.com/articles/scout" className="hk-link" target="_blank">Scout</a>. These types of tools are great for gaining visibility into request queueing in your app.</p>
      }
      </React.Fragment>
    )
}

export default RecommendToolsH12
