import React from "react"
import PropTypes from "prop-types"

import PreviewComment from "./PreviewComment"
import MacroManager from "./macros/MacroManager"
import TicketFormContext from "./context/TicketFormContext"


class TicketCommentForm extends React.Component {

  constructor(props) {
    super(props);

    if (window.supports_localStorage()) {
      var _comment = localStorage.getItem('new-ticket-comment') || '';
    } else {
      var _comment = '';
    }

    this.state = {
      comment: _comment,
      subject: '',
      preview: false,
      preview_available: true,
      monitoring_addons: [],
      logging_addons: []
    };
  }

  static contextType = TicketFormContext;

  componentDidMount = () => {
    window.updateComment = this.updateComment;
  }

  togglePreview = (e) => {
    e.preventDefault();
    this.setState({preview: !this.state.preview});
  }

  handleChange = (event) => {
    this.setState({comment: event.target.value});
  }

  updateComment = (comment) => {
    this.setState({comment: comment});
  }

  previewFailure = () => {
    this.togglePreview();
    this.setState({preview_available: false});
    alert(I18n.t('view.tickets.new.description.preview_failure'));
  }

  keyDown = (e) => {
    if(e.keyCode == 13 && e.metaKey) {
      localStorage.setItem('new-ticket-comment');
      $("#" + this.props.form).submit();
    } else {
      if (window.supports_localStorage()) {
        localStorage.setItem('new-ticket-comment', this.state.comment);
      }
    }
  }

  renderPreviewToggle = () => {
    if (!this.state.preview_available) { return true; }

    var write = I18n.t('view.tickets.new.description.write');
    var preview = I18n.t('view.tickets.new.description.preview');
    if (this.state.preview === true) {
      write = <a tabIndex='-1' className='purple f4 write-preview mr2' href='#' onClick={this.togglePreview}>{write}</a>;
    } else {
      preview = <a tabIndex='-1' className='purple f4 write-preview ml2' href='#' onClick={this.togglePreview}>{preview}</a>;
    }
    return (
      <div key='write-preview' className='write-preview w-100 flex flex-wrap mv1 justify-between'>
        <div className='dark-gray f4 mb2 flex-auto'>
          {write} {preview}
        </div>
        <div className='mb2'>
          <a className='gray f5' target='_blank' rel='noreferrer noopener' tabIndex='-1' href='https://guides.github.com/features/mastering-markdown/'>{I18n.t('view.tickets.new.description.markdown')}</a>
        </div>
      </div>
    );
  }

  renderSimpleNumber = (num) => {
      if(Math.abs(num) < 1000) {  return num;  }

      var shortNumber;
      var exponent;
      var size;
      var sign = num < 0 ? '-' : '';
      var suffixes = {
          'K': 6,
          'M': 9,
          'B': 12,
          'T': 16
      };

      num = Math.abs(num);
      size = Math.floor(num).toString().length;

      exponent = size % 3 === 0 ? size - 3 : size - (size % 3);
      shortNumber = Math.round(10 * (num / Math.pow(10, exponent))) / 10;

      for(var suffix in suffixes) {
          if(exponent < suffixes[suffix]) {
              shortNumber += suffix;
              break;
          }
      }
      return sign + shortNumber;
  }

  renderCharsRemainingCount = () => {
    var remainingCount = 10000 - this.state.comment.length;
    if (remainingCount > 0) {
      return (
        <div className='gray tr'>{I18n.t('view.tickets.new.chars_remaining_preamble') + this.renderSimpleNumber(remainingCount) + I18n.t('view.tickets.new.chars_remaining_postamble') }</div>
      )
    } else {
      return (
        <div className='red tr'>{I18n.t('view.tickets.new.comment_too_long') }</div>
      )
    }
  }

  getPlaceholder = () => {
    if (this.props.label) { return ''}
    return I18n.t('view.tickets.new.description.comment_placeholder');
  }


  renderCommentBox = () => {

    if (this.state.preview) {
      return (<PreviewComment key='comment-preview' comment={this.state.comment} previewFailure={this.previewFailure} />);
    } else {
      return (
        <div key='comment-enter' className='flex flex-column comment-enter'>

          <textarea tabIndex='2'
            placeholder={this.getPlaceholder()}
            id='ticket_comment_body'
            name='ticket[comment][body]'
            onKeyDown={this.keyDown}
            value={this.state.comment}
            className='hk-input w-100 h6 pb0 mb0 br--top relative z-2'
            rows='5'
            required="required"
            maxLength="10000"
            onChange={this.handleChange}
            onBlur={this.handleBlur} />

          <div className='pa3 bg-near-white dark-gray f5 bb br bl bt-0 b--light-gray br1 br--bottom z-1 flex justify-between'>
            <div><input type='file' name='ticket[file_url]' />
              {I18n.t('view.tickets.new.description.drag_and_drop_preamble')} <a href='#' onClick={(e) => e.preventDefault()} className='link underline purple'>{I18n.t('view.tickets.new.description.drag_and_drop_select')}</a> {I18n.t('view.tickets.new.description.drag_and_drop_postamble')}
            </div>
            {this.renderCharsRemainingCount()}
          </div>

          <p className='f5 lh-copy mb0 gray'>
            {I18n.t('view.tickets.new.description.time_zone')}
          </p>
        </div>
      );
    }
  }

  renderTitle = () => {
    if (this.props.show_title) {
      return (<label htmlFor='subject'>{I18n.t('view.tickets.new.description.non_feedback')}</label>)
    }
  }

  renderLabel = () => {
    if (this.props.label) {
      return (<label className="f4 dark-gray lh-copy">{this.props.label}</label>)
    } else {
      return (<label className="f4 dark-gray">{I18n.t('view.tickets.new.description.non_feedback')}</label>)
    }
  }

  render = () => {
    setTimeout(function() {window.prepareForUpload(); window.setup_autocomplete() }, 500);
    if (this.context.reading_macro && this.context.current_macro_placement === 'ticket_subject') return <span/>;
    if (this.props.newTicket) {
      return (
        <React.Fragment>
          <div className="bb b--light-silver flex flex-column flex-row-l pv4">
            <div className="w-30-l w-100 flex-shrink-0 pt0 pt1-l pb2 pb0-l">
              {this.renderLabel()}
            </div>
            <div className="flex-auto flex flex-column dark-gray">
              { this.context.app_name &&
                <MacroManager text={this.state.comment} show_if_placement_is="ticket_comment"/>
              }
              {this.renderPreviewToggle()}
              {this.renderCommentBox()}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className='form-group'>
            {this.renderTitle()}
            {this.renderPreviewToggle()}
            {this.renderCommentBox()}
          </div>
        </React.Fragment>
      );
    }
  }
}

TicketCommentForm.defaultProps = {
  show_title: true,
  form: 'new_ticket_form',
  newTicket: false,
  label: undefined
}

export default TicketCommentForm
