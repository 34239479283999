import React, { useContext } from 'react'

import TicketFormContext from "../context/TicketFormContext"


const NextMacroStep = (props) => {
  const context = useContext(TicketFormContext);
  return(
    <button
    className="hk-button-sm--primary"
    onClick={(e) => context.dispatch({ type: 'CHANGE_MACRO_STEP', macro_step: props.current_step + 1 }, e)}>Next</button>
  )
}

export default NextMacroStep
