import React from "react"

import Loading from "./Loading"

class PreviewComment extends React.Component {

  constructor(props) {
    super(props);
    this.state = { markup: undefined };
    this.authenticity_token = $('meta[name="csrf-token"]').attr('content');
  }

  componentDidMount () {
    this.getMarkup();
  }

  getMarkup = () => {
    $.ajax({
      url: '/tickets/markdownizer',
      method: 'post',
      data: {authenticity_token: this.authenticity_token, content: this.props.comment},
      
      success: function(html) {
        this.setState({markup: html});
      }.bind(this),
      error: function(error) {
        this.props.previewFailure();
      }.bind(this)
    });

    
  }

  render () {
    if (this.state.markup === undefined) {
      return (
        <div className='shadow-outer-1 br2 ph4 pv1 lh-copy'>
          <Loading />
        </div>
      );
    } else {
      return (
        <span>
          <input type='hidden' name='ticket[comment][body]' value={this.props.comment} />
          <div class = 'shadow-outer-1 br1 f4 ph4 pv1 lh-copy'>
          <div className='preview' dangerouslySetInnerHTML={{__html: this.state.markup }} />
          </div>
        </span>
      );
    }
  }
}

export default PreviewComment
