import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"

import Addon from "./Addon"

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    maxWidth              : '800px',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class MonitoringModal extends React.Component {

  constructor () {
    super();
    this.state = {
      preferred_logging_addons: ['papertrail', 'logentries'],
      preferred_monitoring_addons: ['newrelic', 'scout', 'librato']
    }
  }

  renderMontoringAddons = () => {
    var addons = []
    this.shuffleArray(this.props.availableAddons).filter(value => this.state.preferred_monitoring_addons.includes(value.slug)).map((addon) => {
      addons.push(<Addon key={addon.id} addon={addon} />)
    })
    return addons
  }

  renderLoggingAddons = () => {
    var addons = []
    this.shuffleArray(this.props.availableAddons).filter(value => this.state.preferred_logging_addons.includes(value.slug)).map((addon) => {
      addons.push(<Addon key={addon.id} addon={addon} />)
    })
    return addons
  }

  shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  render () {
    return (
      <Modal
           isOpen={this.props.showModal}
           contentLabel="Minimal Modal Example"
           ariaHideApp={false}
           style={customStyles}>

        <div>
          <h1 className='dark-gray f1'>Having performance issues?</h1>
          <p className='dark-gray f4 mv2 lh-copy'>Request timeouts and high response times are best diagnosed using the proper tools.  Please ensure that you're using one of these <strong>FREE add-ons</strong> to help identify the root of the issue.</p>

          <span className='flex justify-between items-center'>
            <p className='dark-gray f2 mt4'>Monitoring add-ons</p>
            <a href="##" className='gray' target="_blank" rel="noreferer">All monitoring add-ons</a>
          </span>

          <div className="flex">
            {this.renderMontoringAddons()}
          </div>

          <span className='flex justify-between items-center'>
            <p className='dark-gray f2 mv4'>Logging add-ons</p>
            <a href="##" className='gray' target="_blank" rel="noreferer">All logging add-ons</a>
          </span>

          <div className="flex">
            {this.renderLoggingAddons()}
          </div>
        </div>

        <button onClick={this.props.handleCloseModal} className='hk-button--primary mt4'>Dismiss</button>
      </Modal>
    )
  }
}

MonitoringModal.defaultProps = {
  showModal: false,
  availableAddons: []
}

export default MonitoringModal
