import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import TicketFormContext from "./context/TicketFormContext"

class SubmitButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ticket_state: "open",
      browser_tz_offset: (new Date().getTimezoneOffset() / 60.0) * -1.0,
      browser_agent: navigator.userAgent
    };
  }

  static contextType = TicketFormContext;

  closeTicket = e => {
    e.preventDefault();
    this.setState(
      {
        ticket_state: "closed"
      },
      function() {
        ReactDOM.findDOMNode(this.refs.submit).click();
      }
    );
  };

  render() {
    if (this.context.reading_macro) return <span/>;
    var closed = "";
    if (this.props.show_closed) {
      closed = (
        <button
          className="right hk-button--danger"
          id="close_button"
          href="#"
          onClick={this.closeTicket}
        >
          {I18n.t("view.tickets.show.close_ticket")}
        </button>
      );
    }
    return (
      <span className="justify-between flex w-100">
        <span>
          <input
            type="hidden"
            name="browser_tz_offset"
            id="browser_tz_offset"
            value={this.state.browser_tz_offset}
          />
          <input
            type="hidden"
            name="browser_agent"
            id="browser_agent"
            value={this.state.browser_agent}
          />
          <input
            type="hidden"
            name="ticket[state]"
            id="ticket_state"
            value={this.state.ticket_state}
          />
          <input
            type="submit"
            tabIndex="10"
            ref="submit"
            value={this.props.value}
            className="hk-button--primary"
          />
        </span>
        {closed}
      </span>
    );
  }
}

SubmitButton.defaultProps = {
  value: "Submit",
  show_closed: false
};

export default SubmitButton;
