import React from "react"
import PropTypes from "prop-types"
import StatusIssue from "./StatusIssue"

class StatusIssues extends React.Component {

  render () {
    var that = this;
    if (this.props.issues.length == 0) return false;

    var issues = [];
    this.props.issues.map(function(issue) {
      issues.push(<StatusIssue key={issue.id} issue={issue} showDetails={that.props.showDetails} />);
    });

    return (
      <div key='issues' className=" bg-lightest-red pl5 pr4 pv4 br1 bw3 b--red bl bg-white hover-bg-near-white bg-animate br1 shadow-outer-1 ">
        {issues}
      </div>
    );
  }
}

StatusIssues.defaultProps = {
  showDetails: false
};

export default StatusIssues
