import React from "react"
import PropTypes from "prop-types"
class StatusScheduled extends React.Component {

  render () {

    if (this.props.events.length == 0) return false;

    return (
      <div className="white shadow-outer-1 pl5 pr4 pv4 br1 bw3 b--blue bl mb3" key={event.full_url}>
        <p className="ttu b lh-copy tracked gray pt0 mt0">Scheduled Maintenance</p>
        {this.props.events.map(function(event) {
        return(
          <p className='dark-gray ttu' key={event.full_url}>
            <a href={event.full_url} target="_blank" rel="noreferrer noopener" className="dark-gray lh-copy ttc">{event.title}</a>
          </p>
          )
      })}
    </div>
    )
  }
}

export default StatusScheduled
