import React from "react"
import Loading from "./Loading"
import DatabaseSelect from "./DatabaseSelect"
import AddonSelect from "./AddonSelect"

class ApplicationSelect extends React.Component {

  constructor(props) {
    super(props);
    window.outstanding_requests = 0;
    this.state = {
      value: undefined,
      uuid: undefined,
      app_name: undefined,
      apps: [],
      loading: true,
      failed: false,
      teams: [],
      team_apps: {}
    };
  }

  componentDidMount = () => {
    this.getTeams();
  }

  checkAppSupportLevel = () => {
    SupportApiRequest({
      url: '/support-level/' + this.state.uuid,
      success: function(data) {
        window.help.const.app_vip = data.sla;
        this.props.updateState({app_vip: data.sla})
        window.help.const.app_pp = data.premier_support;
      }.bind(this),
      error: function() {
        window.help.const.app_vip = false;
        this.props.updateAppVipState({app_vip: false});
        window.help.const.app_pp = false;
      }
    });
  }

  loadApps = () => {
    var _this = this;
    this.loadPersonalApps();
    if (this.state.teams != []) {
      this.state.teams.map(function(team) {
        _this.loadTeamApps(team);
      });
    }
    this.setState({
      loading: false
    });
  }

  loadTeamApps = (team, range = 'name ..; order=asc, max=200;') => {
    fetch(window.help.const["heroku_api"] + '/teams/' + team + '/apps', {
      headers: {
        'Accept': 'application/vnd.heroku+json; version=3',
        'X-Heroku-Legacy-Ids': 'true',
        'Authorization': 'Bearer ' + window.help.const.token,
        'Range': range
      }
    })
    .then((response) => {
      var lastStatus = response.status
      var nextRange = response.headers.get('next-range')
      response.json().then(data => {
        var _team_apps = this.state.team_apps;
        if (_team_apps[team]) {
          _team_apps[team] = _team_apps[team].concat(data)
        } else {
          _team_apps[team] = data
        }

        this.setState({
          team_apps: _team_apps
        });

        lastStatus === 206 ? this.loadTeamApps(team, nextRange) : ''
      })
    })
  }

  loadPersonalApps = () => {
    window.outstanding_requests += 1;
    ApiRequest({
      url: 'users/~/apps',
      headers: {
        'X-Heroku-Legacy-Ids': 'true',
        'Range': 'id ..; max=1000;',
      },
      success: function(data) {
        window.outstanding_requests -= 1;
        this.setState({
          apps: data.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          })
        });
      }.bind(this),
      error: function() {
        window.outstanding_requests -= 1;
        this.setState({
          failed: true,
          loading: false
        });
      }.bind(this)
    });
  }

  getTeams = () => {
    window.outstanding_requests += 1;
    ApiRequest({
      url: 'teams',
      success: function(teams) {
        window.outstanding_requests -= 1;
        this.setState({
          teams: teams.map(function(team){ return team.name; })
        }, function() { this.loadApps(); });
      }.bind(this),
      error: function() {
        window.outstanding_requests -= 1;
        this.setState({
          failed: true,
          loading: false
        });
      }.bind(this)
    });
  }

  handleChange = (e) => {
    this.setState({
      value: $(e.target).val(),
      uuid: $(e.target.options[e.target.selectedIndex]).data('appuuid'),
      app_name: $(':selected', '#app').data('appname'),
      app_team: $(':selected', '#app').data('appteam')
    }, this.getAppAttributes);

    this.props.updateState({
      app_name: $(':selected', '#app').data('appname'),
      blocked_addon: false,
      addon_support_email: undefined
    })
  }

  getAppAttributes = () => {
    this.checkAppSupportLevel();
    this.props.getRunRate(this.state.uuid);
  }

  renderOptions = () => {
    var _options = [];
    var _this = this;
    if (this.state.teams.length === 0) {
      _options = this.renderGroupOptions();
    } else {
      _options.push(<optgroup key="personal-joined" label='PERSONAL AND JOINED APPLICATIONS'>{this.renderGroupOptions()}</optgroup>);
      for (var team in this.state.team_apps) {
        _options.push(<optgroup label={team.toUpperCase()} key={team}>{_this.renderGroupOptions(team)}</optgroup>);
      };
    }
    return _options;
  }

  renderGroupOptions = (team) => {
    var apps = [];
    var _group = [];

    if (team === undefined) {
      apps = this.state.apps;
    } else {
      apps = this.state.team_apps[team];
    }

    if (apps == undefined) {
      return _group;
    }
    apps.map(function(app) {
      var _hid = app.legacy_id.split('@')[0].replace('app', '');
      _group.push(<option value={_hid} data-appname={app.name} data-appteam={app.team ? app.team.name : undefined} key={_hid} data-appuuid={app.id}>{app.name}</option>);
    });
    return _group;
  }

  renderContent = () => {
    if (this.state.loading || window.outstanding_requests > 0)      return ( <Loading /> );
    return (
      <span>
        <select name='ticket[app_id]' onChange={this.handleChange} id='app' className='hk-select' required={this.props.required}>
          <option value=''>{I18n.t('view.tickets.new.choose')}</option>
          {this.renderOptions()}
        </select>
        {this.renderTeamPrompt()}
        {this.renderPermission()}
        <input type="hidden" name="ticket[app_name]" defaultValue={this.state.app_name} />
      </span>
    )
  }

  renderTeamPrompt = () => {
    if (this.state.app_team) {
      return (
        <div className="flex items-center hk-message--info mt2">
          <div className="mr2"><svg className="w2 h2 v-mid"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#pipelines-28"/></svg></div>
          <div className="flex-auto">
            <div className="f4 b lh-title">{I18n.t('view.tickets.new.note')}</div>
            <div className="lh-copy">{I18n.t('view.tickets.new.note_message', {app_team: this.state.app_team})}</div>
          </div>
        </div>

      )
    }
  }

  renderPermission = () => {
    return (
      <div className="f5 mb0 dark-gray lh-copy flex-column pt2">
        {I18n.t('view.tickets.new.allow_access')}
        <span className='tooltip nudge-up--2'>
          <svg className="w1 h1 ml1 fill-gray v-mid">
            <use xlinkHref="#help-badge-16" />
          </svg>
          <span className="tooltiptext">{I18n.t('view.tickets.new.allow_access_help')}</span>
        </span>

        <div className='flex-row pt2'>
          <span className='mr4'>
            <input name="ticket[permission_granted]" id="permission_granted_checkbox" onClick={() => this.props.updateState({permission_granted: true})} className='mr1' type="radio" value='true' required={this.props.required} /> {I18n.t('view.tickets.new.access_yes')}
          </span>
          <span>
            <input name="ticket[permission_granted]" id="permission_granted_checkbox" onClick={() => this.props.updateState({permission_granted: false})} className='mr1' type="radio" value='false' required={this.props.required} /> {I18n.t('view.tickets.new.access_no')}
          </span>
        </div>
      </div>
    )
  }

  render = () => {
    var next_step = [];

    if (this.state.value !== undefined && this.state.app_name !== '')  {
      if (this.props.selectDatabase) {
        next_step.push(<DatabaseSelect  key='databaseselect' app={this.state.app_name} />);
      } else if (this.props.selectAddon) {
        next_step.push(<AddonSelect     key={'addons_'+this.state.app_name}
                                        app={this.state.app_name}
                                        updateFormState={this.props.updateState} />);
      }
    }

    if (this.state.failed) {
      return (
        <div className="bb b--light-silver flex flex-column flex-row-l pv4">
          <p className="f4 dark-gray w-30-l w-100 flex-shrink-0 pt1 pb2 pb0-l ma0">{I18n.t('view.tickets.new.application')}</p>
          <div className='flex-auto flex flex-column'>
            <input type='text' name='ticket[app_name]' id='app' required={this.props.required} className='hk-input' onChange={this.handleChange}/>
            {this.renderPermission()}
          </div>
        </div>
      );
    }

    return (
      <span>
        <div className="bb b--light-silver flex flex-column flex-row-l pv4">
          <p className="f4 dark-gray w-30-l w-100 flex-shrink-0 pt1 pb2 pb0-l ma0">{I18n.t('view.tickets.new.application')}</p>
          <div>
            {this.renderContent()}
          </div>
        </div>
      {next_step}
      </span>
    );
  }
}

export default ApplicationSelect
