import React, { useContext } from 'react'

import TicketFormContext from "../context/TicketFormContext"

const PrevMacroStep = (props) => {
  const context = useContext(TicketFormContext);
  return(
    <button
    className="hk-button-sm--secondary mr3"
    onClick={(e) => context.dispatch({ type: 'CHANGE_MACRO_STEP', macro_step: props.current_step - 1 }, e)}
    >Back</button>
  )
}

export default PrevMacroStep
