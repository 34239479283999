import React from 'react';

import Loading from './Loading';

class TicketUnsubscribe extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      shares: [],
      updating: false
    }
  }

  componentDidMount  () {
    this.getShares();
  }

  getShares = () => {
    SupportApiRequest({
      url: '/tickets/' + this.props.ticket_id + '/shares?sharing_token=' + this.props.sharing_token,
      success: function(shares) {
        this.setState({
          shares: shares.find(share => share.user.email == this.props.email),
          updating: false
        });
      }.bind(this)
    });
  }

  deleteShare = (member) => {
    this.setState({
      updating: true
    },
      SupportApiRequest({
        url: '/tickets/' + this.props.ticket_id + '/shares/' + this.state.shares.id + '?sharing_token=' + this.props.sharing_token,
        method: 'DELETE',
        success: function() {
          this.getShares();
        }.bind(this)
      })
    );
  }

  createShare = (member) => {
    this.setState({
      updating: true
    },
      SupportApiRequest({
        url: '/tickets/' + this.props.ticket_id + '/shares?sharing_token=' + this.props.sharing_token,
        method: 'POST',
        success: function() {
          this.getShares();
        }.bind(this)
      })
    );
  }

  doesHaveShare () {
    return this.state.shares != undefined
  }

  render () {
    if (this.state.updating) {
      return <Loading />;
    }

    if (this.doesHaveShare()) {
      return (
        <button className='hk-button--secondary' onClick={this.deleteShare}>
          <svg className="w1 h1 fill-purple mr1"><use xlinkHref="#remove-badge-16" /></svg>
          <span className='nudge-up--1'>{I18n.t('view.tickets.show.unsubscribe')}</span>
        </button>
      )
    } else {
      return (
        <button className='hk-button--secondary' onClick={this.createShare}>
          <svg className="w1 h1 fill-purple mr1"><use xlinkHref="#add-badge-16" /></svg>
          <span className='nudge-up--1'>{I18n.t('view.tickets.show.subscribe')}</span>
        </button>
      )
    }
  }
}

export default TicketUnsubscribe
