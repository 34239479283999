import React from "react"

class GravatarIcon extends React.Component {

  render () {
    if (this.props.email !== undefined) {
      var url = 'https://secure.gravatar.com/avatar/' + md5(this.props.email.toLowerCase().trim());
      return(
        <img src={url} className='glostick__user__avatar' />
      );
    } else {
      return false;
    }
  }
}

export default GravatarIcon
