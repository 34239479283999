import React, { useContext } from "react"

import TemplateH12 from "./TemplateH12"
import TicketFormContext from "../../context/TicketFormContext"

const h12Python = props => {
  const context = useContext(TicketFormContext);
  return(
    <TemplateH12 last_step={3} updateMacroState={props.updateMacroState}>

      { context.macro_step == 3 &&
        <p>If you have a higher traffic production application we also suggest that you use <a href="http://gunicorn.org/" target="_blank" className="hk-link">Gunicorn</a> so your app's dynos can process more than one request at a time. This will give you more concurrency, help cut back on queue time, and give you more overall performance out of each dyno. If you are already using Gunicorn, its default [timeout] is set to 30 seconds. This should be changed to something much closer to 10-20 seconds (as low as possible without adversely affecting your users).  Since our router will drop requests after 30 seconds, the Gunicorn timeout will never be triggered. Instead, the timeout should be much lower than our router's timeout. This should prevent long-running requests from causing a cascading request queueing buildup. You can read more about setting the Gunicorn timeout here: <a href="https://devcenter.heroku.com/articles/python-gunicorn#worker-timeouts" target="_blank" className="hk-link">https://devcenter.heroku.com/articles/python-gunicorn#worker-timeouts</a></p>
      }
    </TemplateH12>
  )
}

export default h12Python
