import React, { useContext } from "react"

import TemplateH12 from "./TemplateH12"
import TicketFormContext from "../../context/TicketFormContext"

const h12Ruby = props => {
  const context = useContext(TicketFormContext);
  return(
    <TemplateH12 last_step={3} updateMacroState={props.updateMacroState}>
    { context.macro_step == 3 &&
      <React.Fragment>
      <p>You'll also want to install something like the <a href="https://github.com/heroku/rack-timeout" target="_blank" className="hk-link">rack-timeout</a> gem, which will ensure that a long running request is dropped at the dyno-level as well. This gem raises a <code className="hk-badge--code">Rack::TimeoutError</code> exception when this happens.</p>

      <p>With that in place, the compound effect is less likely to occur, but long-running actions still need to be addressed. Again, New Relic is a great tool to provide the visibility into your app to identify the long-running actions. You can then optimize them and make sure they're able to finish within a reasonable time, we suggest keeping all requests under 500ms. If they're performing any inherently long tasks, you should try to offload those to a background worker.</p>
      </React.Fragment>
    }
    </TemplateH12>
  )
}

export default h12Ruby
