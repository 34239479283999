import React from "react"
import PropTypes from "prop-types"
import StatusIssues from "./StatusIssues"
import StatusScheduled from "./StatusScheduled"

class Status extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: { },
      loading: true
    }
  }

  componentDidMount = () => {
    this.getStatus(this);
  }

  getStatus = (comp) => {
    if (!window.help.const.page_hidden) {
      $.ajax({
        url: window.help.const.status_host + '/api/v4/current-status',
        success: function(data) {
          this.setState({
            status: data,
            loading: false
          });
        }.bind(this)
      });
      setTimeout(function() {
        comp.getStatus(comp);
      }, 30000);
    } else {
      setTimeout(function() {
        comp.getStatus(comp);
      }, 1000);
    }
  }

  statusDesc (value) {
    return window.help.const.i18n.t('view.status.' + value);
  }

  getNode (system) {
    return this.state.status.status.find(x=> x.system == system)
  }

  getStateClass (system) {
    str = 'state text-right '
    return str + this.getNode(system).status;
  }

  render () {
    if (this.state.loading) return false;
    if (this.state.status.incidents.length === 0) { return false;  }
    var scheduled = "";
    if (this.props.showScheduled) {
     scheduled = <StatusScheduled events={this.state.status.scheduled} />
    }

    return (
      <div className='flex flex-column w-80 center pa4 incidents'>
        <h3 className='purple'>Platform Status</h3>
        <StatusIssues issues={this.state.status.incidents} showDetails={this.props.showDetails} />
      </div>
    );
  }
}

Status.defaultProps = {
  showScheduled: true,
  showDetails: false
};

export default Status
