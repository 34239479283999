import React from "react"

import PermissionSelect from "./PermissionSelect"
import Loading from "./Loading"

class SignupHelp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      loading: false,
      submitDisabled: true,
      email: this.props.email
    };
  }

  handleChange = (k, v, e) => {
    this.setState({ [k]: v, loading: true }, function() {setTimeout(this.clearLoading, Math.floor(Math.random() * 1000) + 300 )});
  }

  clearLoading = () => {
    this.setState({ loading: false});
  }

  updateEmail = (value) => {
    this.setState({email: value})
  }

  renderWrongEmail = () => {
    if (this.state.step == 5) {
      return (
        <div className='bb b--light-silver flex flex-column pv4'>
          <p className='f3 dark-gray lh-copy'>
            It appears you may have incorrectly entered your email address during sign up.  Please try signing up again.
          </p>
          <a href="https://signup.heroku.com" className='hk-button--primary mv2'>Sign up again</a>
        </div>
      )
    }
  }

  renderEmail = () => {
    if (this.props.email == undefined || this.props.email == '') {
      return (
        <React.Fragment>
          <p className='f3 dark-gray lh-copy'>
            Please enter the email that you are attempting to sign up with:
          </p>
          <input type='email' name='email' className='hk-input' placeholder="Your email address" onChange={(e) => this.updateEmail(e.target.value)} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <p className='f3 dark-gray lh-copy'>
            Please confirm that you are signing up with the following email address:
          </p>
          <p className='f1 near-black'>{this.props.email}</p>
          <input type='hidden' name='email' value={this.props.renderWrongEmail} />
        </React.Fragment>
      )
    }
  }

  renderCheckYourEmail = () => {
    if (this.state.step == 0) {
      return (
        <div className='bb b--light-silver flex flex-column pv4'>

          {this.renderEmail()}

          <button onClick={(e) => this.handleChange('step', 1, e)} className='hk-button--primary mv2'>Yes, this email is correct</button>
          <button onClick={(e) => this.handleChange('step', 5, e)} className='hk-button--danger mv2'>No, This email is not incorrect</button>
        </div>
      )
    }
  }

  renderCheckYouWaited = () => {
    if (this.state.step == 1) {
      return (
        <div className='bb b--light-silver flex flex-column pv4'>
          <p className='f3 dark-gray lh-copy'>
            Please confirm that you have given up to 15 minutes to allow for your sign up email to arrive:
          </p>
          <button onClick={(e) => this.handleChange('step', 2, e)} className='hk-button--primary mv2'>Yes, I have waited at least 15 minutes</button>
        </div>
      )
    }
  }

  renderCheckYourSpam = () => {
    if (this.state.step == 2) {
      return (
        <div className='bb b--light-silver flex flex-column pv4'>
          <p className='f3 dark-gray lh-copy'>
            Please confirm you have checked your email spam/trash folders for your sign up email:
          </p>
          <button onClick={(e) => this.handleChange('step', 3, e)} className='hk-button--primary mv2'>Yes, I have checked</button>
        </div>
      )
    }
  }

  renderContactSupport = () => {
    if (this.state.step == 3) {
      return (
        <div className='bb b--light-silver flex flex-column pv4'>
          <p className='f3 dark-gray lh-copy'>
            As we have been unable to resolve your issue via self-service you will need to contact Heroku Support.  Click below to open a support ticket with our team.
          </p>

          <p className='f3 dark-gray lh-copy'>
            If you have a reference ID, please enter it here:
          </p>
          <input type='reference_id' name='reference_id' className='hk-input' placeholder="Reference Id" />

          <input type='submit' disabled={this.state.submitDisabled} className='hk-button--primary mv2' value="Create support request" />
        </div>
      )
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <div className="bg-lightest-silver flex-auto pv4 ph3">
          <div className="mw8 mw8-ns center pa3 ph5-ns bg-white br1 shadow-outer-1 mv6">
            <Loading />
          </div>
        </div>
      )
    } else {
      return (
        <div className="bg-lightest-silver flex-auto pv4 ph3">
          <div className="mw8 mw8-ns center pa3 ph5-ns bg-white br1 shadow-outer-1 mv6">
            {this.renderWrongEmail()}
            {this.renderCheckYourEmail()}
            {this.renderCheckYouWaited()}
            {this.renderCheckYourSpam()}
            {this.renderContactSupport()}
          </div>
          <input type='hidden' name='email' value={this.state.email || ''} />
          <input type='hidden' name='signup_help' value={true} />
        </div>
      );
    }
  }
}

export default SignupHelp
