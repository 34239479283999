import React from 'react'

const FlagButton = props => {
  function render() {
    return(
      <>
        <input className="hk-button-sm--warning mb4" type="button" defaultValue={props.btnText} onClick={props.flagArticle} disabled={props.disabled}/>
        { props.disabled &&
          <input className="hk-button-sm--tertiary ml2" type="button" defaultValue="Unflag article" onClick={props.unFlagArticle}/>
        }
      </>
    )
  }

  return render()
}

export default FlagButton
