import React from "react"

import Loading from "./Loading"
import TicketComment from "./TicketComment"


class TicketComments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comments: [],
      newCommentBanner: false,
      failed: false
    };
  }

  componentDidMount () {
    this.loadComments();
    window.loadComments = this.loadComments;
  }

  componentDidUpdate () {
    autolink();
  }

  loadComments = () => {
    var _this = this;
    if (!window.help.const.page_hidden) {
      SupportApiRequest({
        url: '/tickets/' + this.props.ticket_id + '/comments?sharing_token=' + this.props.sharing_token,
        success: function(data) {
          // filter to public comments only
          var new_comments = data;

          // do we want to show the new comment banner
          var new_comment_banner = false;
          if (new_comments.length != this.state.comments.length && this.state.comments.length !== 0) {
            new_comment_banner = true;
          }

          _this.setState({
            newCommentBanner: new_comment_banner,
            loading: false,
            comments: new_comments.reverse()
          });
        }.bind(this),
        error: function() {
          _this.setState({
            failed: true,
            loading: false
          });
        }
      });
    }
  }

  buildComment = (comment, index) => {
    return (<TicketComment comment={comment} key={comment.id} index={index} />);
  }

  render () {
    var banner = '';
    if (this.state.loading) {
      return ( <Loading /> );
    }

    if (this.state.newCommentBanner === true) {
      var href = '#comment_' + (this.state.comments.length-1);
      banner = (
        <div className="center ba br1 pa2 mv4 w-100 bg-lightest-green green b--light-green white flex-column">
          A new comment has been posted to your ticket.  <a href={href} className='green'>Please see below.</a>
        </div>
      );
    }

    return (
      <div className='mt6'>
        {banner}
        {this.state.comments.map(this.buildComment)}
      </div>
    );
}
  }

export default TicketComments
