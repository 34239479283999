import React from 'react'

const ticketFormContext = React.createContext({
  app_name: '',
  current_macro_placement: '',
  macro_name: '',
  macro_step: 1,
  dispatch: () => {}
})

export default ticketFormContext
