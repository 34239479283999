import React from 'react';
import axios from 'axios'
import FlagButton from './article_flag/FlagButton'
import FlagCommentForm from './article_flag/FlagCommentForm'

class ArticleFlag extends React.Component {
  constructor(props) {
    super(props);
    this.btnText = this.props.btn_text || "Flag for review";
    this.state = {
      flagged: this.props.flagged,
      comment: this.props.comment,
      flagId: this.props.flag_id,
      showForm: false,
    }
    axios.defaults.baseURL = this.props.base_url;
    axios.defaults.headers.common['Accept'] = '*/*';
  }

  render() {
    return(
      <div>
        <FlagButton
          btnText={this.btnText}
          flagArticle={this.flagArticle}
          unFlagArticle={this.unFlagArticle}
          disabled={this.state.flagged}
        />
        <FlagCommentForm
          comment={this.state.comment}
          flagId={this.state.flagId}
          showForm={(this.state.showForm)}
          dismissSubmit={this.dismissComment}
          submitComment={this.submitComment}
          charLimit={this.props.char_limit}
        />
      </div>
    )
  }

  flagArticle = e => {
    e.preventDefault();
    axios.post('/api/articles/' + this.props.article_id + '/flag',
    {
      authenticity_token: this.props.authenticity_token,
      user_id: this.props.user_id
    })
    .then((response) => {
      this.setState({
        flagId: response.data.flag_id,
        flagged: true,
        showForm: true,
      })
    })
    .catch(err => console.log("Something went wrong"));
  }

  unFlagArticle = e => {
    e.preventDefault();
    axios.post(`/api/articles/${this.props.article_id}/unflag`,
    {
      authenticity_token: this.props.authenticity_token,
      user_id: this.props.user_id
    })
    .then(response => {
      this.setState({
        flagId: null,
        flagged: false,
        showForm: false,
        comment: ""
      })
    })
    .catch(err => console.log(err));
  }

  submitComment = text => {
    axios.post(`/api/articles/${this.props.article_id}/flag`,
    {
      authenticity_token: this.props.authenticity_token,
      comment: text,
      flag_id: this.state.flagId,
    })
    .then((response) => {
      this.setState({
        showForm: false,
        comment: text
      })
    })
    .catch(err => console.log("Something went wrong"));
  }

  dismissComment = () => {
    this.setState({ showForm: false })
  }

}

export default ArticleFlag;
