import React from "react"

class SharingBlurb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sharing_token: this.props.sharing_token
    };
  }

  rollToken = () => {
    SupportApiRequest({
      url: '/tickets/' + this.props.ticket_id + '/roll_token',
      method: 'POST',
      success: function(data) {
        this.setState({
          sharing_token: data.sharing_token
        });
      }.bind(this)
    })
  }

  render () {
    var linkStyle = {
      cursor: 'pointer'
    };

    return (<div className='sharing-blurb w-100'>
      <p className="f5 lh-copy near-black mt0">{I18n.t('view.tickets.show.sharing.preamble')}</p>

      <input id="shareURL" readOnly={true} value={"https://help.heroku.com/sharing/" + this.state.sharing_token} className='hk-input--read-only w-100' />

      <div className='tr pt2'>
        <a onClick={this.rollToken} className='f5 link purple' style={linkStyle}>{I18n.t('view.tickets.show.sharing.new_url')}</a>
      </div>
    </div>)
  }
}

export default SharingBlurb
