import React from 'react'
import * as _ from 'lodash';

import Loading from "./Loading"
import TicketTableRow from "./TicketTableRow"
import PurpleDropdown from "./PurpleDropdown"


class TeamTickets extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        tickets: [],
        teams: [],
        apps: {},
        filters: {
          state: this.props.state || '',
          team: ''
        },
        pagination: {
          page: 1
        }
      };
    }

    componentDidMount = () => {
      this.loadTeams();
    }

    capitalize = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    abbreviation(name) {
      if (!name) { return ''; }
      const lettersOnly = name.split('').filter((c) => new RegExp('([a-z\D])', 'i').test(c)).join('');
      return this.capitalize(lettersOnly.slice(0, 2).toLowerCase());
    }

    groupBy = (list, keyGetter) => {
      const map = new Map();
      list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
      });
      return map;
    }

    loadTeams = () => {
      ApiRequest({
        url: 'teams',
        success: function(data) {
          this.setState({
            teams: data.filter(team => team.role == 'admin')
          }, this.loadApps());
        }.bind(this),
        error: function () {
        }.bind(this)
      })
    }

    loadApps = () => {
      ApiRequest({
        url: 'apps',
        success: function(data) {
          let team_apps = data.filter(app => this.state.teams.map(team => team.name).includes(_.get(app, 'team.name')))
          let apps = {}
          team_apps.map(app => {
            apps[app.name] = app.team.name
          })
          this.setState({
            apps: apps
          }, this.loadTickets());
        }.bind(this),
        error: function () {
        }.bind(this)
      })
    }

    loadTickets = () => {
      SupportApiRequest({
        url: '/team',
        success: function(tickets) {
          var _tickets = tickets.map(ticket => {
            ticket.team = this.state.apps[ticket.app_name]
            return ticket
          })
          this.setState({
            tickets: _tickets,
            loading: false
          });
        }.bind(this)
      });
    }

    sortTickets = (tickets) => {
      return tickets.sort(function(a, b) {
        return new Date(b.last_public_change_at) - new Date(a.last_public_change_at);
      });
    }

    filterTickets = (tickets) => {
      var _tickets = tickets;
      var _this = this;
      if (this.state.filters.state == 'open|pending') {
        _tickets =  tickets.filter(ticket => (ticket.state == 'open') || (ticket.state == 'pending'));
      }
      if (this.state.filters.state == 'open') {
        _tickets =  tickets.filter(ticket => ticket.state == 'open');
      }
      if (this.state.filters.state == 'pending') {
        _tickets =  tickets.filter(ticket => ticket.state == 'pending');
      }
      if (this.state.filters.state == 'closed') {
        _tickets =  tickets.filter(ticket => ticket.state == 'closed' || ticket.state == 'archived')
      }
      if (this.state.filters.team != '') {
        _tickets =  _tickets.filter(ticket => ticket.team == _this.state.filters.team);
      }
      return _tickets;
    }

    renderRow = (ticket, index) => {
      var per_page = this.props.pagination.per_page;
      var page = this.state.pagination.page;
      if ((index >= per_page * (page-1)) && index < per_page * page) {
        return (<TicketTableRow key={ticket.id} ticket={ticket} index={index} />);
      }
    }

    setPage = (page) => {
      this.setState({
        pagination: {
          page: page
        }
      });
    }

    changeStateFilter = (state) => {
      var filters = this.state.filters;
      filters.state = state;
      this.setState({
        filters: filters,
        pagination: {
          page: 1
        }
      }, () => window.dispatchEvent(new Event('selectedFilter')));
    }

    changeTeamFilter = (team) => {
      var filters = this.state.filters;
      filters.team = team;
      this.setState({
        filters: filters,
        pagination: {
          page: 1
        }
      }, () => window.dispatchEvent(new Event('selectedFilter')));
    }

    renderTeams = () => {
      var ret = [];
      const groupedTeams = this.groupBy(this.state.teams, team => (team.enterprise_account ? team.enterprise_account.name : "Non-Enterprise Teams"));

      ret.push(
        <li key={Math.floor(Math.random() * 0xFFFF) }>
          <a className="hk-dropdown-item" onClick={() => this.changeTeamFilter('')}>
            <span className="flex-auto truncate ml2">
              {I18n.t('view.tickets.index.all_team')}
            </span>
          </a>
        </li>)

      groupedTeams.forEach((teams, account) => {
        ret.push(
          <li key={account} className='flex items-center f4 link dark-gray db pv1'>
            <a className="hk-dropdown-item dropdown-account">
              <div className="hk-identicon">{this.abbreviation(account)}</div>
              <span className="flex-auto truncate ml2">
                {account}
              </span>
            </a>
          </li>)

        teams.map(team => {
          ret.push(
            <li key={team.name}>
              <a className="hk-dropdown-item" onClick={() => this.changeTeamFilter(team.name)}>
                <span className="b--light-gray bl bb pa1 mh2 nudge-up--3"></span>
                <div className="hk-identicon--circle">{this.abbreviation(team.name)}</div>
                <span className="flex-auto truncate ml2 hover-purple">
                  {team.name}
                </span>
              </a>
            </li>)
        })
      })

      return ret
    }

    render () {
      if (this.state.loading) {
        return (
          <div className='flex justify-center mv4'>
            <Loading />
          </div>
        );
      } else {
        var tickets = this.sortTickets(this.filterTickets(this.state.tickets));
        var filters = [];

        if (this.props.useFilters) {
          if (this.state.teams.length > 1) {
            var _team_filter = (
              <PurpleDropdown label={I18n.t('view.tickets.index.filter_by_team')} key='team-dd'>
                {this.renderTeams()}
              </PurpleDropdown>
            )
            filters.push(_team_filter)
          }

          filters.push(
            <PurpleDropdown label={I18n.t('view.tickets.index.filter_by_state')} key='state-dd'>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('')}>{I18n.t('view.tickets.index.all_states')}</a></li>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('open')}>{I18n.t('view.tickets.state.open')}</a></li>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('pending')}>{I18n.t('view.tickets.state.pending')}</a></li>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('closed')}>{I18n.t('view.tickets.state.closed')}</a></li>
            </PurpleDropdown>
          );
        }

        var table = '';
        if (tickets.length) {
          table = (
            <div className="flex flex-column w-100 ph4 items-center pt6-l pt4">
              {tickets.map(this.renderRow)}
            </div>
          );
        } else {
          if (this.state.filters.state.indexOf('open') > -1) {
            table = (
              <div className='flex flex-column w-100 ph4 items-center pt6-l pt4 tc'>
                <span className='gray f4'>{I18n.t('view.tickets.index.no_open_tickets')}</span>
              </div>
            );
          } else {
            table = (
              <div className='flex flex-column w-100 ph4 items-center pt6-l pt4 tc'>
                <span className='gray f4'>{I18n.t('view.tickets.index.no_tickets')}</span>
              </div>
            );
          }
        }

        var buttons = [];
        for (var i = 1; i < (tickets.length / this.props.pagination.per_page)+1 ; i++ ) {
          var classes = 'hk-button--secondary';
          if (i == this.state.pagination.page) classes = ' hk-button--primary';
          buttons.push(<button type='button' key={i} onClick={this.setPage.bind(this, i)}className={classes}>{i}</button>);
        }
        if (buttons.length == 1) buttons = [];

        return (
          <div>
            <div className='flex justify-center w-100 center bg-lightest-silver bb b--light-silver pv3 ph4'>
              <div className="flex w-80-l w-100 h3 justify-between items-center">
                <div className="gray mh1 ttu f5 b tracked flex-auto">
                  {I18n.t('navigation.team_tickets')}
                </div>
                <div>{filters}</div>
              </div>
            </div>

            {table}

            <div className='flex justify-center hk-button-group pb6'>
              {buttons}
            </div>
          </div>
        );
      }
    }
  }

  TeamTickets.defaultProps = {
    showOpenToggle: false,
    pagination: {
      per_page: 15
    }
  }


export default TeamTickets
