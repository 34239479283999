import React from "react"

class FreeAppRedirect extends React.Component {

    render () {
        return (
            <div key="freeRestriction" className="hk-message--warning mb4">
                <div className="flex-auto">
                    <p key="1">
                        Technical support for <a className="f4 b orange" href="https://www.heroku.com/pricing">Free applications</a> is provided by the online community.
                    </p>

                    <p key="2">
                        For all programming related questions related to particular languages or frameworks, please visit the <a className="f4 b orange" href='https://stackoverflow.com/questions/tagged/heroku'>Stack Overflow community</a>.
                        Stack Overflow is a great place to get help from fellow Heroku users by <a className="f4 b orange" href="https://stackoverflow.com/questions/tagged/heroku">searching</a> thousands of previous questions or <a className="f4 b orange" href="https://stackoverflow.com/questions/ask">asking your own</a>.

                        For all questions related to the Heroku platform or specific Heroku products, please visit the <a className="f4 b orange" href='https://salesforce.stackexchange.com/questions/tagged/heroku'>Salesforce Stack Exchange community</a>.
                    </p>

                    <p key="3">
                        Finally, documentation can be found in our <a className="f4 b orange" href="https://help.heroku.com">Knowledge Base</a> and <a className="f4 b orange" href="https://devcenter.heroku.com">DevCenter articles</a>.
                        Technical support is provided on all paid applications. Visit your <a className="f4 b orange" href="https://dashboard.heroku.com">Heroku Dashboard</a> to <a href="https://devcenter.heroku.com/articles/dyno-types#dashboard" className="f4 b orange">upgrade to the Hobby Tier</a>. Please note that any changes made to your account's billing status can take up to 30 minutes before this message is removed.
                    </p>
                </div>
            </div>
        )
    }
}

export default FreeAppRedirect
