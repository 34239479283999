import React from "react"
import PropTypes from "prop-types"
import TicketFormContext from "../context/TicketFormContext"

import H12Java from "./H12/H12Java"
import H12Node from "./H12/H12Node"
import H12Php from "./H12/H12Php"
import H12Python from "./H12/H12Python"
import H12Ruby from "./H12/H12Ruby"
import H12Generic from "./H12/H12Generic"

class H12MacroManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_macro: false,
      app_language: ""
    }
  }

  static contextType = TicketFormContext;

  checkAppLanguage = (event) => {
    event.preventDefault();
    var _this = this;

    ApiRequest({
      url: 'apps/' + _this.context.app_name,
      success: function(response) {
        _this.setState({
          app_language: response.buildpack_provided_description,
          show_macro: true
        });
        _this.context.dispatch({ type: 'CHANGE_MACRO_STEP', macro_step: 1 })
        _this.context.dispatch({type: 'READING_MACRO' });
        _this.context.dispatch({ type: 'SET_MACRO_NAME', macro_name: `H12 – ${response.buildpack_provided_description}` })
      }.bind(this),
      error: function() {
      }.bind(this)
    });
  }


  render() {
    var h12LanguageMacro;

    switch (this.state.app_language) {
      case "Java":
        h12LanguageMacro = <H12Java/>
        break;
      case "Node.js":
        h12LanguageMacro = <H12Node/>
        break;
      case "PHP":
        h12LanguageMacro = <H12Php/>
        break;
      case "Python":
        h12LanguageMacro = <H12Python/>
        break;
      case "Ruby":
        h12LanguageMacro = <H12Ruby/>
        break;
      default:
        h12LanguageMacro = <H12Generic/>
    }

    return(
      <div>
        { this.state.show_macro && this.context.current_macro_placement == this.props.show_if_placement_is &&
          h12LanguageMacro
        }

        { !this.state.show_macro && this.context.current_macro_placement == this.props.show_if_placement_is &&
          <div className="flex items-center hk-message--warning mv3">
            <div className="mr2"><svg className="w2 h2 v-mid"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#metrics-28"/></svg></div>
            <div className="flex-auto">
              <div className="f4 b lh-title">Facing performance issues?</div>
              <div className="lh-copy">It sounds like you might be facing H12 errors or slow response times.<br/> Is that correct? <a className="link purple" href="#" onClick={this.checkAppLanguage}>Yes</a> • <a className="link purple" href="#" onClick={(e) => this.context.dispatch({ type: 'DISMISS_MACRO' })}>No</a></div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default H12MacroManager
