import React from 'react';

import TicketCount from './TicketCount'

class TeamTicketsLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      admin_teams_number: 0
    }
  }

  getAdminTeams () {
    ApiRequest({
      url: 'teams',
      success: function(data) {
        this.setState({
          admin_teams_number: data.filter(team => team.role == 'admin').length
        });
      }.bind(this),
      error: function () {
      }.bind(this)
    })
  }

  componentDidMount () {
    this.getAdminTeams()
  }

  render () {
    if (this.state.admin_teams_number > 0) {
      return (
        <a className="tc mh3 ph1 pt0 pv4 gray link hover-dark-gray no-underline" href="/tickets/team">
          <span>{I18n.t('navigation.team_tickets')}</span>
          <TicketCount api_path='/team' />
        </a>
      )
    } else {
      return <span />;
    }
  }
}

export default TeamTicketsLink
