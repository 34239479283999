import React, { useContext } from "react"

import IntroH12 from "./IntroH12"
import MacroButtons from "../MacroButtons"
import RecommendToolsH12 from "./RecommendToolsH12"
import TicketFormContext from "../../context/TicketFormContext"

const TemplateH12 = props => {
  const context = useContext(TicketFormContext);

  return(
    <div className="dark-gray lh-copy hk-well mv4 tl">
      <IntroH12/>
      <RecommendToolsH12/>

      {props.children}

      <MacroButtons last_step={props.last_step}/>
    </div>
  )
}

export default TemplateH12
