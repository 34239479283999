import React from 'react'

class PurpleDropdown extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      filter_visible: false,
      id: Math.floor(Math.random() * 100000)
    }
  }

  componentDidMount = () => {
    window.addEventListener('selectedFilter', e => this.closeDropDown(e))
  }

  componentWillUnmount = () => {
    window.addEventListener('selectedFilter', e => this.closeDropDown(e))
  }

  closeDropDown = (e) => {
    if (!e || (e && e.detail != this.state.id)) {
      this.setState({
        filter_visible: false
      })
    }
  }

  toggleFilterVisibility = (e) => {
    if (!this.state.filter_visible) {
      window.dispatchEvent(new CustomEvent('selectedFilter', { detail: this.state.id }))
    }
    e.preventDefault()
    this.setState({
      filter_visible: !this.state.filter_visible
    })
  }

  render () {
    return (
      <div className="relative dib cursor-pointer" key='state-dd'>
        <div className='context-item hk-no-link-style flex items-center purple pv--3 pl1 br2  hover-bg-light-silver' onClick={this.toggleFilterVisibility}>
          <span className="ml2 dn inline-flex-l">
            {this.props.label}
          </span>
          <button className="context-toggle hk-no-link-style flex items-center bn bg-transparent br2 pt2 ph--3 nudge-left--2 nr--2">
            <span className="h2">
              <svg className="w1 h1 fill-purple mr1"><use xlinkHref="#caret-16" /></svg>
            </span>
          </button>
        </div>
        <ul className="hk-dropdown--left" style={!this.state.filter_visible ? {'display': 'none'} : undefined}>
          {this.props.children}
        </ul>
      </div>
    )
  }
}

export default PurpleDropdown
