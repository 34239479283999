import React, { useContext } from "react"
import TicketFormContext from "../context/TicketFormContext"

const macroButtons = (props) => {
  const context = useContext(TicketFormContext);
  return(
    <button
    className="hk-button-sm--danger"
    onClick={(e) => context.dispatch({ type: 'DONE_READING_MACRO' }, e)}>I understand and still require additional help</button>
  )
}

export default macroButtons
