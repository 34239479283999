import React from "react"
import PropTypes from "prop-types"

import TicketFormContext from "./context/TicketFormContext"

class PrioritySelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      priority: 'normal',
      confirmed: false
    };
  }
  static contextType = TicketFormContext;

  uppercase = (string) =>  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleChange = (e) => {
    this.setState({ priority: e.target.value });
  }

  confirmPriority = (e) => {
    this.setState({confirmed: e.target.checked})
  }

  priorityOptions = () => {
    var options = [];
    options.push(<option key='low' value='low'>{I18n.t('view.tickets.new.priority.low')}</option>);
    options.push(<option key='normal' value='normal'>{I18n.t('view.tickets.new.priority.normal')}</option>);
    options.push(<option key='high' value='high'>{I18n.t('view.tickets.new.priority.high')}</option>);
    options.push(<option key='urgent' value='urgent'>{I18n.t('view.tickets.new.priority.urgent')}</option>);

    if (window.help.const.pp || window.help.const.app_pp) {
      options.push(<option key='critical' value='critical'>{I18n.t('view.tickets.new.priority.critical')}</option>);
    }
    return options;
  }

  renderAppHelper = () => {
    if (this.props.form_type == 0 || this.props.permission_granted) { return <span /> }
    return (
      <p className='lh-copy pl4 pv0 mt2' id='permission_warning' dangerouslySetInnerHTML={{__html: I18n.t('view.tickets.new.urgent.warning_app')}} />
    )
  }

  renderConfirmPage = () => {
    if (this.state.priority == 'urgent' || this.state.priority == 'critical') {
      return (
        <div className="bb b--light-silver flex flex-column flex-row-l pv4">
          <p className="f4 dark-gray w-30-l w-100 flex-shrink-0 lh-copy mt1"></p>
          <div className='w-100'>
            <div className='pa4  red bg-lightest-red ba b--light-red br1 lh-copy flex'>
              <svg className="w3 h3 fill-red">
                <use xlinkHref="#warning-badge-28" />
              </svg>
              <div>
                <p className='lh-copy pl4 pv0 mv0' dangerouslySetInnerHTML={{__html: I18n.t('view.tickets.new.'+this.state.priority+'.warning_intro', {priority: I18n.t('view.tickets.new.priority.'+this.state.priority)})}} />
                <p className='lh-copy pl4 pv0 mv0' dangerouslySetInnerHTML={{__html: I18n.t('view.tickets.new.'+this.state.priority+'.description')}} />
                {this.renderAppHelper()}
                <p className='lh-copy pl4 pv0 mt2 mb0'>
                  <input type='checkbox' id='confirm_priority' name='confirm_priority' className='mr2' onChange={this.confirmPriority} />
                  <label htmlFor='urgent'><strong>{I18n.t('view.tickets.new.urgent.urgent_ticket', {priority: I18n.t('view.tickets.new.priority.'+this.state.priority)})}</strong></label>
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  renderValue = () => {
    var value = this.state.priority;
    if ((this.state.priority == 'urgent' || this.state.priority == 'critical') && this.state.confirmed != true) {
      value = 'normal'
    }
    return (
      <input type='hidden' name='ticket[priority]' value={value} />
    )
  }

  render () {
    if (!(window.help.const.vip || window.help.const.app_vip) || this.context.reading_macro) { return <span />; };
    return (
      <span>
        <div className="bb b--light-silver flex flex-column flex-row-l pv4">
          <div className='w-100 w-30-l flex-shrink-0 pt2-l pt0 pb2'>
            <label className='f4 dark-gray'>
                {I18n.t('view.tickets.new.priority.label')}
            </label>
          </div>
          <div className='w-100'>
            <select name='placeholder_priority' id='placeholder_priority' className='hk-select' onChange={this.handleChange} defaultValue="normal">
              {this.priorityOptions()}
            </select>
            <p className="f6 lh-copy red" id='placeholder_priority_warning' style={{display: 'none'}}>{I18n.t('view.tickets.new.priority.placeholder_warning')}</p>
          </div>
        </div>
        {this.renderConfirmPage()}
        {this.renderValue()}
      </span>
    );
  }
}

export default PrioritySelect
