import React from "react"

const satisfaction_options = [
   I18n.t('view.tickets.survey.satisfaction.excellent'),
   I18n.t('view.tickets.survey.satisfaction.good'),
   I18n.t('view.tickets.survey.satisfaction.neutral'),
   I18n.t('view.tickets.survey.satisfaction.poor'),
   I18n.t('view.tickets.survey.satisfaction.horrible')
]

const resolution_score_options = [
   I18n.t('view.tickets.survey.resolution_score.excellent'),
   I18n.t('view.tickets.survey.resolution_score.good'),
   I18n.t('view.tickets.survey.resolution_score.neutral'),
   I18n.t('view.tickets.survey.resolution_score.poor'),
   I18n.t('view.tickets.survey.resolution_score.horrible')
]
const satisfaction_reason_options = {
  '5': [
    I18n.t('view.tickets.survey.satisfaction_reason.speed_of_response'),
    I18n.t('view.tickets.survey.satisfaction_reason.thoroughness_of_answer'),
    I18n.t('view.tickets.survey.satisfaction_reason.empathy_and_communication'),
    I18n.t('view.tickets.survey.satisfaction_reason.other')
  ],
  '4': [
    I18n.t('view.tickets.survey.satisfaction_reason.speed_of_response'),
    I18n.t('view.tickets.survey.satisfaction_reason.thoroughness_of_answer'),
    I18n.t('view.tickets.survey.satisfaction_reason.empathy_and_communication'),
    I18n.t('view.tickets.survey.satisfaction_reason.other')
  ],
  '3': [],
  '2': [
    I18n.t('view.tickets.survey.satisfaction_reason.speed_of_response'),
    I18n.t('view.tickets.survey.satisfaction_reason.thoroughness_of_responses'),
    I18n.t('view.tickets.survey.satisfaction_reason.product_issue'),
    I18n.t('view.tickets.survey.satisfaction_reason.other')
  ],
  '1': [
    I18n.t('view.tickets.survey.satisfaction_reason.speed_of_response'),
    I18n.t('view.tickets.survey.satisfaction_reason.thoroughness_of_responses'),
    I18n.t('view.tickets.survey.satisfaction_reason.product_issue'),
    I18n.t('view.tickets.survey.satisfaction_reason.other')
  ]
}

const generic_class = 'w-100 ba bg-animate pointer survey_button satisfied pa4 hk-button--secondary';
const selected_class = ' b--light-purple bg-lightest-purple selected_survey';
const non_selected_class = ' b--light-silver bg-lightest-silver hover-bg-white';

class SurveyForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      satisfaction_score: '',
      resolution_score_options: [],
      resolution_score: '',
      satisfaction_reason_options: [],
      satisfaction_reason: '',
      ticket_id: '',
      comments: null
    };
  }

  setScoreOne = (e) => {
    e.preventDefault();
    e.target.blur();
    this.setState({
      satisfaction_score: e.target.value,
      resolution_score_options: resolution_score_options,
      resolution_score: '',
      satisfaction_reason_options: [],
      satisfaction_reason: ''
    }, function() {
      this.postSurvey();
    });
  }

  setScoreTwo = (e) => {
    e.preventDefault();
    e.target.blur();
    this.setState({
      resolution_score: e.target.value,
      satisfaction_reason_options: satisfaction_reason_options[this.state.satisfaction_score],
      satisfaction_reason: ''
    }, function() {
      this.postSurvey();
    });
  }

  setScoreThree = (e) => {
    e.preventDefault();
    e.target.blur();
    this.setState({
      satisfaction_reason: e.target.value
    }, function() {
      this.postSurvey();
    });
  }

  setComments = (e) => {
    e.preventDefault();
    this.setState({
      comments: e.target.value
    }, function() {
      this.postSurvey();
    });
  }

  setClass = (score, position_score) => {
    return generic_class + (position_score == score ? selected_class : non_selected_class );
  }

  postSurvey = () => {
    SupportApiRequest({
      method: 'POST',
      url: '/tickets/' + this.props.ticket_id + '/surveys',
      data: {
        satisfaction_score: this.state.satisfaction_score,
        resolution_score: this.state.resolution_score,
        satisfaction_reason: this.state.satisfaction_reason,
        comments: this.state.comments
      }
    })
  }

  render () {

    var comment_box = '';

    //Step 2
    if (this.state.resolution_score_options.length > 0) {
      var step_2 = (
        <div>
          <input type='hidden' value={this.state.resolution_score} name='survey[resolution_score]' />
          <p className="f4 near-black lh-copy ma0 mb3 pt4">{I18n.t('view.tickets.survey.resolution_score.step_two')}</p>
          <div className='flex flex-column hk-button-group'>
            <div>
              <button type='button' value='5' onClick={this.setScoreTwo} className={this.setClass(5, this.state.resolution_score)}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.resolution_score_options[0]}</p>
              </button>
            </div>
            <div>
              <button type='button' value='4' onClick={this.setScoreTwo} className={this.setClass(4, this.state.resolution_score)}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.resolution_score_options[1]}</p>
              </button>
            </div>
            <div>
              <button type='button' value='3' onClick={this.setScoreTwo} className={this.setClass(3, this.state.resolution_score)}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.resolution_score_options[2]}</p>
              </button>
            </div>
            <div>
              <button type='button' value='2' onClick={this.setScoreTwo} className={this.setClass(2, this.state.resolution_score)}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.resolution_score_options[3]}</p>
              </button>
            </div>
            <div>
              <button type='button' value='1' onClick={this.setScoreTwo} className={this.setClass(1, this.state.resolution_score)}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.resolution_score_options[4]}</p>
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      var step_2 = '';
    }

    // Step 3
    if (this.state.satisfaction_reason_options.length > 0) {
      var step_3 = (
        <div>
          <input type='hidden' value={this.state.satisfaction_reason} name='survey[satisfaction_reason]' />
          <p className="f4 near-black lh-copy ma0 mb3 pt4">{I18n.t('view.tickets.survey.resolution_score.step_three')}</p>
          <div className='flex flex-column hk-button-group'>
            <div>
              <button type='button' value={this.state.satisfaction_reason_options[0]} onClick={this.setScoreThree} className={this.setClass(0, this.state.satisfaction_reason_options.indexOf(this.state.satisfaction_reason))}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.satisfaction_reason_options[0]}</p>
              </button>
            </div>
            <div>
              <button type='button' value={this.state.satisfaction_reason_options[1]} onClick={this.setScoreThree} className={this.setClass(1, this.state.satisfaction_reason_options.indexOf(this.state.satisfaction_reason))}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.satisfaction_reason_options[1]}</p>
              </button>
            </div>
            <div>
              <button type='button' value={this.state.satisfaction_reason_options[2]} onClick={this.setScoreThree} className={this.setClass(2, this.state.satisfaction_reason_options.indexOf(this.state.satisfaction_reason))}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.satisfaction_reason_options[2]}</p>
              </button>
            </div>
            <div>
              <button type='button' value={this.state.satisfaction_reason_options[3]} onClick={this.setScoreThree} className={this.setClass(3, this.state.satisfaction_reason_options.indexOf(this.state.satisfaction_reason))}>
                <p className='f4 ma0 near-black lh-copy pe-none'>{this.state.satisfaction_reason_options[3]}</p>
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      var step_3 = '';
    }

    // Comment section
    if (this.state.resolution_score != '') {
      var comment_box = (
        <div className='flex justify-center pt4 mt4 bt b--light-silver'>
          <div className='flex flex-column center w-100 mw6 justify-around'>
            <p className='f4 dark-gray lh-copy ma0 pb2'>{I18n.t('view.tickets.survey.satisfaction_reason.further_comments')}{this.state.satisfaction_reason_options.indexOf(this.state.satisfaction_reason) == '3' && ' *'}</p>
            <textarea className='hk-input w-100' name='survey[comments]' onBlur={this.setComments} required={this.state.satisfaction_reason_options.indexOf(this.state.satisfaction_reason) == '3'} />
            <p><input type='submit' className='hk-button--primary' value={I18n.t('view.tickets.survey.send_feedback')}/></p>
          </div>
        </div>
      );
    } else {
      var comment_box = ''
    }

    return (
      <span id='survey_form'>
        <div className='flex flex-column hk-button-group'>
          <input type='hidden' value={this.state.satisfaction_score} name='survey[satisfaction_score]' />
          <div>
            <button type='button' value='5' onClick={this.setScoreOne} className={this.setClass(5, this.state.satisfaction_score)}>
              <p className='f4 ma0 near-black lh-copy pe-none'>{satisfaction_options[0]}</p>
            </button>
          </div>
          <div>
            <button type='button' value='4' onClick={this.setScoreOne} className={this.setClass(4, this.state.satisfaction_score)}>
              <p className='f4 ma0 near-black lh-copy pe-none'>{satisfaction_options[1]}</p>
            </button>
          </div>
          <div>
            <button type='button' value='3' onClick={this.setScoreOne} className={this.setClass(3, this.state.satisfaction_score)}>
              <p className='f4 ma0 near-black lh-copy pe-none'>{satisfaction_options[2]}</p>
            </button>
          </div>
          <div>
            <button type='button' value='2' onClick={this.setScoreOne} className={this.setClass(2, this.state.satisfaction_score)}>
              <p className='f4 ma0 near-black lh-copy pe-none'>{satisfaction_options[3]}</p>
            </button>
          </div>
          <div>
            <button type='button' value='1' onClick={this.setScoreOne} className={this.setClass(1, this.state.satisfaction_score)}>
              <p className='f4 ma0 near-black lh-copy pe-none'>{satisfaction_options[4]}</p>
            </button>
          </div>
        </div>
        {step_2}
        {step_3}
        {comment_box}
      </span>

    );
  }
}

export default SurveyForm
