import React from "react"

class SurveyForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      score: '',
      ticket_id: ''
    };
  }

  setScore = (e) => {
    e.preventDefault();
    e.target.blur();
    this.setState({
      score: e.target.value
    }, function() {
      this.postSurvey();
    });
  }

  postSurvey = () => {
    SupportApiRequest({
      method: 'POST',
      url: '/tickets/' + this.props.ticket_id + '/surveys',
      data: {
        support_score: this.state.score
      }
    })
  }

  render () {
    var yes_classes = 'w6 ba br2 bg-animate pointer mh2 pv2 survey_button satisfied';
    var no_classes  = 'w6 ba br2 bg-animate pointer mh2 pv2 survey_button disatisfied';
    var comment_box = '';

    if (this.state.score == '1') {
      yes_classes += ' b--light-green bg-lightest-green selected_survey';
    } else {
      yes_classes += ' b--light-silver bg-lightest-silver hover-bg-white';
    }

    if (this.state.score == '0') {
      no_classes += ' b--light-red bg-lightest-red selected_survey';
    } else {
      no_classes += ' b--light-silver bg-lightest-silver hover-bg-white';
    }

    if (this.state.score != '') {
      var comment_box = (
        <div className='flex justify-center pt4 mt4 bt b--light-silver'>
          <div className='flex flex-column center w-100 mw6 justify-around'>
            <p className='f4 dark-gray lh-copy ma0 pb2'>Do you have any further comments?</p>
            <textarea className='hk-input w-100' name='survey[comments]' />
            <p><input type='submit' className='hk-button--primary' value="Send Feedback" /></p>
          </div>
        </div>
      );
    }

    return (
      <span id='survey_form'>
        <input type='hidden' value={this.state.score} name='survey[support_score]' />

        <div className='flex flex-column flex-row-ns justify-center items-center w-100 pv2 ph2'>
          <div className='pa2'>
            <button type='button' value='1' onClick={this.setScore} className={yes_classes}>
              <svg className="w4 h4 fill-green center pe-none">
                <use xlinkHref="#success-ring-28" />
              </svg>
              <p className='f4 ma0 near-black lh-copy pe-none'>I am satisfied</p>
            </button>
          </div>
          <div className='pa2'>
            <button type='button' value='0' onClick={this.setScore} className={no_classes} value='0'>
              <svg className="w4 h4 fill-red center pe-none">
                <use xlinkHref="#error-ring-28" />
              </svg>
              <p className='f4 ma0 near-black lh-copy pe-none'>I am dissatisfied</p>
            </button>
          </div>
        </div>
        {comment_box}
      </span>

    );

  }

}

export default SurveyForm
