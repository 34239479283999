import React from "react"

import PermissionSelect from "./PermissionSelect"
import Loading from "./Loading"

class AddonSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addons: [],
      failed: false,
      value: undefined,
      loading: true
    };
  }

  componentDidMount () {
    this._isMounted = true
    this.loadDatabases();
  }


  componentWillUnmount () {
    this._isMounted = false
  }

  loadDatabases = () => {
    ApiRequest({
      url: 'apps/' + this.props.app + '/addons',
      success: function(data) {
        if (!this._isMounted) { return; }
        this.setState({
          addons: data.filter(function(addon) {
            return jQuery.inArray(addon.addon_service.name, ['herokuconnect', 'heroku-postgresql', 'heroku-redis', 'heroku-kafka']) === -1;
          }).sort(function(a, b) {
            return a.plan.name.localeCompare(b.plan.name);
          }),
          loading: false
        });
      }.bind(this),
      error: function() {
        if (!this._isMounted) { return; }
        this.setState({
          addons: [],
          loading: false
        })
      }.bind(this)
    })
  }

  checkAddon = (value) => {
    console.log(value)
    SupportApiRequest({
      url: `/addons_support_email/${value.split(":")[0]}`,
      success: function(data) {
        this.props.updateFormState({
          blocked_addon: true,
          addon_support_email: data.email_support
        })
      }.bind(this),
      error: function(data) {
        this.props.updateFormState({
          blocked_addon: false,
          addon_support_email: undefined
        })
      }.bind(this)
    })
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value }, () => {
      this.checkAddon(this.state.value)
    });
  }

  render () {
    if (this.state.loading) { return ( <Loading /> ) };

    var next_step = [];
    if (this.state.value !== undefined) {
      next_step.push(<PermissionSelect />);
    }

    var options = [];
    var _this = this;
    this.state.addons.map(function(addon) {
      var plan = addon.plan.name;
      var plan_name = plan + " - " + addon.name;
      if (addon.app.name != _this.props.app) {
        plan_name += " (Attached to " + addon.app.name + ")"
      }
      options.push(<option key={plan} value={plan}>{plan_name}</option>);
    });

    options.push(<option key='other' value='other'>{I18n.t('view.tickets.new.not_added_addon')}</option>);

    return (
      <div className="bb b--light-silver flex flex-column-m pv4">
        <p className="f4 dark-gray w-30 w-100-m flex-shrink-0 lh-copy mt1 mt0-m mb2-m">{I18n.t('view.tickets.new.addon')}</p>
        <select name='ticket[addon]' onChange={this.handleChange} id='addon' className='hk-select'>
          <option value=''>{I18n.t('view.tickets.new.choose')}</option>
          {options}
        </select>
      </div>
    )
  }
}

export default AddonSelect
