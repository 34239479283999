import React from "react"

class TicketTableRow extends React.Component {

  renderSVG = () => {
    if (this.props.ticket.app_name == '' || this.props.ticket.app_name == undefined) { return <span /> }
    return <svg className="w1 h1 fill-purple mr1"><use xlinkHref="#app-16" /></svg>
  }

  renderTeam () {
    if (this.props.ticket.team) {
      return (
        <span className='flex'>
          <svg className="w1 h1 fill-purple mr1"><use xlinkHref="#team-16" /></svg>
          <span className='db f4'>{this.props.ticket.team}</span>
        </span>
      )
    }
  }

  render  () {
    var state, style;

    switch(this.props.ticket.state) {
      case 'open':
        state = I18n.t('view.tickets.state.open');
        style = 'orange bg-lightest-orange';
        break;
      case 'pending':
        state = I18n.t('view.tickets.state.pending');
        style = 'orange bg-lightest-orange';
        break;
      case 'archived':
        state = I18n.t('view.tickets.state.closed');
        style = 'white bg-light-gray';
        break;
      case 'closed':
        state = I18n.t('view.tickets.state.closed');
        style = 'white bg-light-gray';
        break;
    }
    style += ' fw6 no-margin text-center ph2 pv1 br1';
    var href = '/' + this.props.ticket.id;
    return (
      <a className='flex flex-column items-center w-70-l w-100 mb6-l mb4 ph4 pv3 bg-animate bg-white hover-bg-near-white br1 shadow-outer-1 purple no-underline' href={href}>
        <div className='flex items-center w-100 pb3 mb3 bb b--light-silver'>
          <div className='flex flex-column flex-row-ns items-center-ns flex-auto'>
            <span className='f3 fw6 purple mr2 lh-copy'>#{this.props.ticket.id}</span>
            <span className='f4 no-underline flex-auto lh-copy'>{this.props.ticket.subject}</span>
          </div>
          <span className={style}>{state}</span>
        </div>
        <div className='flex w-100 justify-between'>
          <span className='f4 gray'>
            {I18n.t('view.tickets.index.table.last_updated')} <b>{moment(this.props.ticket.last_public_change_at).format("YYYY-MM-DD")}</b>
          </span>

          <span className='flex'>
            {this.renderTeam()}
            <span className='flex tr ml4'>
              {this.renderSVG()}
              <span className='db f4'>{this.props.ticket.app_name}</span>
            </span>
          </span>
        </div>
      </a>
    );
  }
}

export default TicketTableRow
