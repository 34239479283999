import React, { useContext } from 'react'

import TicketFormContext from "../../context/TicketFormContext"

const introH12 = () => {
  const context = useContext(TicketFormContext);
  return(
      <React.Fragment>
      { context.macro_step == 1 &&
        <React.Fragment>
        <h3>Let's see if we can help you diagnose this.</h3>

        <p>We generally see this pattern where one long-running action starts hogging the queue which in turn affects any subsequent requests.</p>

        <p>Our router will drop a long-running request after 30 seconds, but the dyno behind it will continue processing the request until completion. Our router is unaware of it, though, so it'll dispatch new requests to that busy dyno. This effect tends to compound, and you'll see queueing in New Relic and eventually <a href="https://devcenter.heroku.com/articles/error-codes#h12-request-timeout" target="_blank" className="hk-link">H12</a> errors even for unrelated URLs, such as static assets. <a href="https://devcenter.heroku.com/articles/error-codes#h13-connection-closed-without-response" target="_blank" className="hk-link">H13</a> errors are similar in what causes them, but are primarily related to concurrent web servers.</p>
        </React.Fragment>
      }
      </React.Fragment>
  )
}

export default introH12
