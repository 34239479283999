import React from "react"

import Loading from "./Loading"
import TicketTableRow from "./TicketTableRow"

class TicketTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tickets: [],
      filters: {
        state: this.props.state || ''
      },
      pagination: {
        page: 1
      },
      state_filter_visible: false
    };
  }


  componentDidMount () {
    this.loadTickets();
  }

  loadTickets = () => {
    SupportApiRequest({
      url: '/tickets',
      success: function(tickets) {
        this.setState({
          tickets: tickets,
          loading: false
        });
      }.bind(this)
    });
  }

  sortTickets = (tickets) => {
    return tickets.sort(function(a, b) {
      return new Date(b.last_public_change_at) - new Date(a.last_public_change_at);
    });
  }

  filterTickets = (tickets) => {
    var _tickets = tickets;
    if (this.state.filters.state == 'open|pending') {
      _tickets =  tickets.filter(function(ticket) {
        return ((ticket.state == 'open') || (ticket.state == 'pending'));
      });
    }
    if (this.state.filters.state == 'open') {
      _tickets =  tickets.filter(function(ticket) {
        return (ticket.state == 'open');
      });
    }
    if (this.state.filters.state == 'pending') {
      _tickets =  tickets.filter(function(ticket) {
        return (ticket.state == 'pending');
      });
    }
    if (this.state.filters.state == 'closed') {
      _tickets =  tickets.filter(function(ticket) {
        return (ticket.state == 'closed' || ticket.state == 'archived');
      });
    }
    return _tickets;
  }

  renderRow = (ticket, index) => {
    var per_page = this.props.pagination.per_page;
    var page = this.state.pagination.page;
    if ((index >= per_page * (page-1)) && index < per_page * page) {
      return (<TicketTableRow key={ticket.id} ticket={ticket} index={index} />);
    }
  }

  setPage = (page) => {
    this.setState({
      pagination: {
        page: page
      }
    });
  }

  changeStateFilter = (state) => {
    this.setState({
      filters: {
        state: state
      },
      pagination: {
        page: 1
      },
      state_filter_visible: false
    });
  }

  toggleStateFilter = (e) => {
    e.preventDefault()
    this.setState({
      state_filter_visible: !this.state.state_filter_visible
    })
  }

  render () {
    if (this.state.loading) {
      return (
        <div className='flex justify-center mv4'>
          <Loading />
        </div>
      );
    } else {
      var tickets = this.sortTickets(this.filterTickets(this.state.tickets));

      // closed ticket toggle
      var filters = [];

      if (this.props.useFilters) {
        filters.push(
          <div className="relative dib" key='state-dd'>
            <div className='context-item hk-no-link-style flex items-center purple pv--3 ph1 pr2 br2' onClick={this.toggleStateFilter}>
              <span className="ml2 dn inline-flex-l">
                {I18n.t('view.tickets.index.filter_by_state')}
              </span>
              <button className="context-toggle hk-no-link-style flex items-center bn bg-transparent br2 pt2 ph--3 nudge-left--2 nr--2">
                <span className="h2">
                  <svg className="w1 h1 fill-purple mr1"><use xlinkHref="#caret-16" /></svg>
                </span>
              </button>
            </div>
            <ul className="hk-dropdown--left" style={!this.state.state_filter_visible ? {'display': 'none'} : undefined}>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('')}>{I18n.t('view.tickets.index.all_states')}</a></li>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('open')}>{I18n.t('view.tickets.state.open')}</a></li>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('pending')}>{I18n.t('view.tickets.state.pending')}</a></li>
              <li><a className="hk-dropdown-item" onClick={() => this.changeStateFilter('closed')}>{I18n.t('view.tickets.state.closed')}</a></li>
            </ul>
          </div>
        );
      }

      var table = '';
      if (tickets.length) {
        table = (
          <div className="flex flex-column w-100 ph4 items-center pt6-l pt4">
            {tickets.map(this.renderRow)}
          </div>
        );
      } else {
        if (this.state.filters.state.indexOf('open') > -1) {
          table = (
            <div className='flex flex-column w-100 ph4 items-center pt6-l pt4 tc'>
              <span className='gray f4'>{I18n.t('view.tickets.index.no_open_tickets')}</span>
            </div>
          );
        } else {
          table = (
            <div className='flex flex-column w-100 ph4 items-center pt6-l pt4 tc'>
              <span className='gray f4'>{I18n.t('view.tickets.index.no_tickets')}</span>
            </div>
          );
        }
      }

      var buttons = [];
      for (var i = 1; i < (tickets.length / this.props.pagination.per_page)+1 ; i++ ) {
        var classes = 'hk-button--secondary';
        if (i == this.state.pagination.page) classes = ' hk-button--primary';
        buttons.push(<button type='button' key={i} onClick={this.setPage.bind(this, i)}className={classes}>{i}</button>);
      }
      if (buttons.length == 1) buttons = [];

      return (
        <div>
          <div className='flex justify-center w-100 center bg-lightest-silver bb b--light-silver pv3 ph4'>
            <div className="flex w-80-l w-100 h3 justify-between items-center">
              <div className="gray mh1 ttu f5 b tracked flex-auto">
                {I18n.t('view.tickets.index.title')}
              </div>
              <div>{filters}</div>
            </div>
          </div>

          {table}

          <div className='flex justify-center hk-button-group pb6'>
            {buttons}
          </div>
        </div>
      );
    }
  }
}

TicketTable.defaultProps = {
  showOpenToggle: false,
  pagination: {
    per_page: 15
  }
}

export default TicketTable
