import React from "react"

class PermissionSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  }

  render () {
    next_step = [];

    if (this.state.value != '') {
      next_step.push(<PrioritySelect key='PrioritySelect' />);
    }

    return (
      <span>
        <div className='form-group'>
          <label htmlFor='permission'>{I18n.t('view.tickets.new.allow_access')}</label>
          <div>
            <label className='radio-inline'>
              <input onChange={this.handleChange} name="ticket[permission_granted]" type="radio" value="true" />
              Yes
            </label>
            <label className='radio-inline'>
              <input onChange={this.handleChange} name="ticket[permission_granted]" type="radio" value="false" />
              No
            </label>
          </div>
          <p className='help-block'>{I18n.t('view.tickets.new.allow_access_help')}</p>
        </div>
        {next_step}
      </span>
    );
  }
}

export default PermissionSelect
