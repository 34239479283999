import React from "react"

class TicketResponseTime extends React.Component {

  constructor(props) {
    super(props);
  }

  is_urgent_or_critial = () => {
    return((this.props.priority == 'critical') || (this.props.priority == 'urgent'));
  }

  is_premier_or_priority = () => {
    return ((this.props.supportLevel.table.support_tier == "enterprise_premier") || (this.props.supportLevel.table.support_tier == "enterprise_priority"))
  }

  render () {
    var message = this.props.initialMessage === undefined ? '' : this.props.initialMessage;
    var messageClass = "mv4 bg-light-yellow b--yellow ba pa2 br1 w-100";

    if (this.is_premier_or_priority()) {
      if (this.is_urgent_or_critial()) {
        message += I18n.t("helper.tickets_helper.response_time_message.vip_page")
      } else {
        message += I18n.t("helper.tickets_helper.response_time_message.vip_non_holiday") 
      }

    } else {
        message += I18n.t("helper.tickets_helper.response_time_message.paid");
        message += `<h5>${I18n.t("view.tickets.expectations.nonvip_sla_title")}</h5>`;
        message += `<p>${I18n.t("view.tickets.expectations.nonvip_sla_html")}</p>`;
    }

    if (this.props.category == "feedback") {
      return (
        <span className="ticket_response_time">
          <p>{I18n.t("view.tickets.expectations.feedback")}</p>
        </span>
      );
    } else {
      return (
        <div className={messageClass}>
          {  this.props.showBanner ?
            <p className="f4 fw7 near-black lh-copy ma0">{I18n.t("view.tickets.expectations.title")}</p>
          : null }
        <p className="f4 near-black lh-copy ma0" dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      );
    }
  }
}

TicketResponseTime.defaultProps = {
  category: "none",
  showBanner: true,
  initialMessage: '',
  supportLevel: null,
  priority: 'normal'
}

export default TicketResponseTime
