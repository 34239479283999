import React from "react"
import PropTypes from "prop-types"
class StatusIssue extends React.Component {

  renderMarkdown (text) {
    return {__html: window.markdown.toHTML( text )};
  }

  getDate () {
    return (
      <div className='dark-gray lh-copy'>
        {new Date(this.props.issue.updates[0].created_at).toRelativeTime()}
      </div>
    );
  }

  render() {
    var _class = 'flex flex-auto justify-between dark-gray pb3 f4';

    return (
      <div>
        <div className={_class} key={this.props.issue.id}>
          <a target="_blank" rel="noreferrer noopener" href={this.props.issue.full_url} className='dark-gray'>{this.props.issue.title}</a>
          {this.getDate()}
        </div>
        <div className='dark-gray f4 lh-copy'>
          <div dangerouslySetInnerHTML={this.renderMarkdown(this.props.issue.updates[0].contents)} />
        </div>
      </div>
    );
  }
}

export default StatusIssue
