import React from "react"
import PropTypes from "prop-types"

import MacroManager from "./macros/MacroManager"
import TicketFormContext from "./context/TicketFormContext"

class TicketSubject extends React.Component {

  constructor(props) {
    super(props);

    if (window.supports_localStorage()) {
      var _subject = localStorage.getItem('new-ticket-subject');
    } else {
      var _subject = '';
    }

    this.state = {
      value: _subject, showWarning: false, relatedLinks: []
    };

    this.relatedLinks = debounce(this.relatedLinks, 250);
  }

  static contextType = TicketFormContext;

 setRelatedLinks = () => {
  var that = this;
  $.getJSON( window.help.const.kb_host + "/api/articles/search.json?q=" + that.state.value)
    .done( function(data) {
      that.setState({ relatedLinks: data });
    });
  }

  handleChange = (e) => {
    this.setState({value: e.target.value});
    if (window.supports_localStorage()) {
      localStorage.setItem('new-ticket-subject', e.target.value);
    }
  }

  handleKeyUp = (e) => {
    this.setState({
      value: e.target.value
    })
  }

  hasValidSubject = () => {
    return (this.state.value && this.state.value.length > 15);
  }

  checkLength = () => {
    if (this.hasValidSubject() ) {
      this.setRelatedLinks();
      this.setState({ showWarning: false });
    } else {
      this.setState({ showWarning: true });
    }
  }

  render = () => {
    if (this.props.preDefSubject) {
      return (
        <input type="hidden" id='ticket_subject' name='ticket[subject]' defaultValue={this.props.preDefSubject} />
      )
    } else {

      return (
        <div className="bb b--light-silver flex flex-row-l flex-column pv4">
          <div className="w-100 w-30-l flex-shrink-0 pt2-l pt0 pb2">
            <label className="f4 dark-gray">{I18n.t('view.tickets.new.subject.title')}</label>
          </div>
          <div className="flex-auto flex flex-column">
            <input placeholder={I18n.t('view.tickets.new.subject.title_placeholder')} value={this.state.value} onKeyUp={this.handleKeyUp} onBlur={this.checkLength} onChange={this.handleChange} type='text' tabIndex='1' className='flex-auto hk-input' id='ticket_subject' name='ticket[subject]' maxLength='255' required={true} />
            { this.state.showWarning &&
              <p className='f6 lh-copy red'>
                {I18n.t('view.tickets.new.subject.short')}
              </p>
            }
            { this.context.app_name &&
              <MacroManager text={this.state.value} show_if_placement_is="ticket_subject"/>
            }

            { !this.context.reading_macro && this.context.current_macro_placement !== 'ticket_subject' && this.hasValidSubject() && this.state.relatedLinks.length > 0 &&
              <div id='related_links' className='u-is-hidden alert alert-success'>
                <h3 className="dark-gray">{I18n.t('view.tickets.new.subject.related')}</h3>
                <ul>
                  {this.state.relatedLinks.map(function(value, index){
                      return <li key={ index }><a href={ 'https://help.heroku.com/' + value.identifier + '/' + value.slug } className="purple" target='_blank' rel='noopener noreferrer'>{value.title}</a></li>;
                    })}
                </ul>
              </div>
            }

          </div>
        </div>
      );

    }

  }
}

TicketSubject.defaultProps = {
  preDefSubject: undefined
}

export default TicketSubject
