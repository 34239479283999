import React, { useContext } from "react"

import TemplateH12 from "./TemplateH12"
import TicketFormContext from "../../context/TicketFormContext"

const h12Java = props => {
  const context = useContext(TicketFormContext);
  return(
    <TemplateH12 last_step={2} updateMacroState={props.updateMacroState}>

    </TemplateH12>
  )
}

export default h12Java
