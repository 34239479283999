import React from "react"

import GravatarIcon from "./GravatarIcon"
import TicketResponseTime from "./TicketResponseTime"

class TicketComment extends React.Component {

  createMarkup = () => {
    return {__html: this.renderComment()};
  }

  getUserEmail = () => {
    if (this.props.comment.meta_data.author_email) {
      return this.props.comment.meta_data.author_email;
    } else {
      return this.props.comment.actor.email;
    }
  }

  getAuthorName = () => {
    if (this.props.comment.meta_data && this.props.comment.meta_data.author_name) {
      return this.props.comment.meta_data.author_name;
    } else {
      return this.props.comment.actor.name || this.props.comment.actor.full_name || this.props.comment.actor.email.split('@')[0]
    }
  }

  renderIcon = () => {
    if (this.props.comment.meta_data && this.props.comment.meta_data.zendesk_source) {
      var source =  this.props.comment.meta_data.zendesk_source;
      var src = "https://addons.heroku.com/addons/" + source + "/icons/original.png";
      return <img src={src} alt={this.props.comment.meta_data.zendesk_source} />
    } else if (this.props.comment.meta_data && this.props.comment.meta_data.source) {
      var src = "https://addons.heroku.com/addons/zendesk/icons/original.png";
      return <img src={src} />
    } else {
      return <GravatarIcon email={this.props.comment.actor.email} />;
    }
  }

  renderDate = () => {
    var d_names = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
    var m_names = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
    var d = new Date();

    var commentDate = new Date(this.props.comment.created_at);
    var yesterday = d.setDate(d.getDate() - 1);

    if (commentDate > yesterday) {
      return new Date(this.props.comment.created_at).toRelativeTime({ nowThreshold: 60000 })
    } else {
      var curr_day = commentDate.getDay();
      var curr_date = commentDate.getDate();
      var sup = "";
      if (curr_date == 1 || curr_date == 21 || curr_date ==31)
         { sup = "st"; }
      else if (curr_date == 2 || curr_date == 22)
         { sup = "nd"; }
      else if (curr_date == 3 || curr_date == 23)
         { sup = "rd"; }
      else
         { sup = "th"; }
      var curr_month = commentDate.getMonth();
      var curr_year = commentDate.getFullYear();

      return (d_names[curr_day] + " " + curr_date + sup + " " + m_names[curr_month] + " " + curr_year);
    }
  }

  isFirstComment = () => {
    return this.props.index == 0;
  }

  renderComment = () => {
    var comment_body_html = this.props.comment.body_html;
    comment_body_html = comment_body_html.replace(new RegExp(window.help.const.agent_asset_host, "g"), window.help.const.asset_host);
    return comment_body_html.autoLink();
  }

  render () {
    if (this.props.comment.public === false) return <span />;
    var role = '';
    if (this.props.comment.actor.herokai) {
      role = (<span className="fw6 purple flex items-center"><svg className="w1 h1 fill-purple mr1"><use xlinkHref="#logo-block-16" /></svg></span>);
    } else if (this.props.comment.actor.addon_provider || (this.props.comment.meta_data && (this.props.comment.meta_data.zendesk_source || this.props.comment.meta_data.source))) {
      role = (<span className="fw6 gray flex items-center"><svg className="w1 h1 fill-dark-gray mr1"><use xlinkHref="#marketing-addon-48" /></svg>Add-on Provider</span>);
    }
    return (
      <div id={`comment_${this.props.index}`} className='w-100 bt b--light-silver pa4'>
        <a name={'comment_' + this.props.index} />

        { this.isFirstComment() && this.props.anonymous_ticket && role == '' ?
          <div className='flex flex-column-m'>
            <div className='w-25-l w-100 flex-shrink-0'>
            </div>
            <div className='w-75-l w-100 tc'>
              <TicketResponseTime showBanner={false} initialMessage={I18n.t("helper.tickets_helper.new_ticket_header")} />
            </div>
          </div>
        : null }


        <div className='flex flex-column-m heroku-comment' id={'ticketcomment-' + this.props.comment.id}>
          <div className='w-25-l w-100 flex-shrink-0 dark-gray'>
            <div className='glostick__user__avatar--container'>
              {this.renderIcon()}
            </div>
            <div className='flex items-center'>
              <p className='f3 mr1 mt2 mb2 lh-copy'>{this.getAuthorName()}</p>
              {role}
            </div>
            <p className='f5 lh-copy ma0' title={this.props.comment.created_at}>{this.renderDate()}</p>
          </div>

          <div className='w-75-l w-100 f4 near-black lh-copy mv0 heroku-comment-body' dangerouslySetInnerHTML={this.createMarkup()} />
        </div>
      </div>
    );
  }
}

export default TicketComment
