import React from "react"
import _ from "lodash"

import Loading from "./Loading"

class DatabaseSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      databases: [],
      failed: false,
      value: undefined,
      loading: true,
      app: undefined
    };
  }

  componentDidMount () {
    this.state.app = this.props.app;
    this._isMounted = true
    this.loadDatabases();
  }

  componentWillReceiveProps (newProps) {
    if (!_.isEqual(newProps,this.props)) {
      this.setState({
        databases: [],
        loading: true,
        value: undefined,
        app: newProps.app
      }, function() {
        this.loadDatabases();
      });
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  loadDatabases = () => {
    ApiRequest({
      url: 'apps/' + this.state.app + '/addons',
      success: function(data) {
        if (!this._isMounted) { return; }
        this.setState({
          databases: data.filter(function(addon) {
            addon.type = addon.plan.name.split(":")[0]
            return jQuery.inArray(addon.addon_service.name, ['herokuconnect', 'heroku-postgresql', 'heroku-redis', 'heroku-kafka']) !== -1;
          }).sort(function(a, b) {
            return a.type.localeCompare(b.type);
          }),
          loading: false
        });
      }.bind(this),
      error: function () {
        if (!this._isMounted) { return; }
        this.setState({
          databases: [],
          loading: false
        })
      }.bind(this)
    })
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  }

  renderOptions = () => {
    var _options = [];
    var _this = this;
    _options.push(
      <optgroup key='heroku-postgresql' label='HEROKU POSTGRES'>
        {this.renderGroupOptions('heroku-postgresql')}
        <option key='other-postgresql' value='Other Postgresql'>{I18n.t('view.tickets.new.not_added_postgres')}</option>
      </optgroup>);
    _options.push(
        <optgroup key='heroku-connect' label='HEROKU CONNECT'>
          {this.renderGroupOptions('herokuconnect')}
          <option key='other-connect' value='Other Connect'>{I18n.t('view.tickets.new.not_added_connect')}</option>
        </optgroup>);
    _options.push(
      <optgroup key='heroku-redis' label='HEROKU REDIS'>
        {this.renderGroupOptions('heroku-redis')}
        <option key='other-redis' value='Other Redis'>{I18n.t('view.tickets.new.not_added_redis')}</option>
      </optgroup>);
    _options.push(
      <optgroup key='heroku-kafka' label='HEROKU KAFKA'>
        {this.renderGroupOptions('heroku-kafka')}
        <option key='other-kafka' value='Other Kakfa'>{I18n.t('view.tickets.new.not_added_kafka')}</option>
      </optgroup>);
    return _options
  }

  renderGroupOptions = (group) => {
    return this.state.databases.filter(function(addon) {
      return addon.addon_service.name == group
    }).sort(function(a, b) {
      return a.name.localeCompare(b.name);
    }).map(function(db) {
      var color = db.config_vars[0];
      var plan = db.plan.name.split(":")[1];

      var value = {
        type: group,
        value: db.name
      };

      if (group == 'heroku-kafka') {
        color = findKafkaURL(db.config_vars);
      }

      var str = '';
      if (color) {str += color + ' / '};
      str += db.name;

      return <option key={db.name} value={JSON.stringify(value)}>{str}</option>;
    });
  }

  render () {
    if (this.state.loading) { return ( <Loading /> ) };

    return (

      <div className="bb b--light-silver flex flex-column-m pv4">
        <p className="f4 dark-gray w-30 w-100-m flex-shrink-0 lh-copy mt1 mt0-m mb2-m">{I18n.t('view.tickets.new.database')}</p>

        <select name='ticket[db]' onChange={this.handleChange} id='db' className='hk-select' required={true}>
          <option value=''>{I18n.t('view.tickets.new.choose')}</option>
          {this.renderOptions()}
        </select>
      </div>
    )
  }
}

export default DatabaseSelect

function findKafkaURL(array){
    var arrKeys = array.length;
    var match = "";
    // we want KAFKA_URL
    // not KAFKA_PLAINTEST_URL
    var patt = new RegExp(/^[A-Z]+_URL$/);
    for(var i=0; i < arrKeys; i++ ){
        if(patt.test(array[i]))
           match = array[i];
    }
    return match;
}
