import React, { useContext } from "react"

import NextMacroStep from "./NextMacroStep"
import PrevMacroStep from "./PrevMacroStep"
import RequestMoreHelpButton from "./RequestMoreHelpButton"
import TicketFormContext from "../context/TicketFormContext"

const macroButtons = (props) => {
  const context = useContext(TicketFormContext);
  return(
    <div className="tr">
      { context.macro_step !== 1 && context.macro_step !== 0 &&
        <PrevMacroStep current_step={context.macro_step}/>
      }
      { context.macro_step !== props.last_step && context.macro_step !== 0 &&
        <NextMacroStep current_step={context.macro_step}/>
      }

      { context.macro_step == props.last_step && context.reading_macro &&
        <React.Fragment>
          {props.custom_button}
          <RequestMoreHelpButton/>
        </React.Fragment>
      }
    </div>
  )
}

export default macroButtons
