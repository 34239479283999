import React from "react"

import SharingBlurb from "./SharingBlurb"

class TicketSharing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      shares: [],
      updating: false,
      sharing_token: this.props.sharing_token,
      enterprise_account: this.props.enterprise_account,
      boosted:  this.props.boosted
    };
  }

  componentDidMount  () {
    this.getShares();
  }

  getShares = () => {
    SupportApiRequest({
      url: '/tickets/' + this.props.ticket_id + '/shares',
      success: function(shares) {
        this.setState({
          shares: shares.map(function(share){ return share.user.email; })
        });
      }.bind(this)
    });
  }

  outputMember = (member) => {
    var checked = this.state.shares.indexOf(member) > -1;
    return (
      <div key={member} className='share-member checkbox'>
        <label>
          <span><a onClick={this.deleteShare.bind(null, member)}><i className="fa fa-times" aria-hidden="true"></i></a></span> {member}
        </label>
      </div>
    );
  }

  deleteShare = (member) => {
    SupportApiRequest({
      url: '/tickets/' + this.props.ticket_id + '/shares/' + member,
      method: 'DELETE',
      success: function() {
        this.setState({
          updating: false
        }, this.getShares());
      }.bind(this)
    });
  }

  handleChange = (e) => {
    if (e.target.checked) {
      this.addShare(e.target.value);
      this.setState({
        shares: this.state.shares.concat([e.target.value]).filter(onlyUnique),
        updating: true,
      });
    } else {
      this.deleteShare(e.target.value);
      var shares = this.state.shares;
      var index = shares.indexOf(e.target.value);
      shares.splice(index, 1);
      if (index > -1) {
        this.setState({
          updating: true,
          shares: shares
        });
      }
    }
  }

  escalateTicket = (e) => {
    e.target.disabled = true;
    e.persist();
    SupportApiRequest({
      url: '/tickets/' + this.props.ticket_id,
      method: 'PATCH',
      data: {
        boosted: true
      },
      success: function() {
        this.setState({
          updating: false
        }, this.ticketEscalated(e));
      }.bind(this)
    });
  };

  ticketEscalated = (e) => {
    e.target.disabled = true;
    e.target.innerHTML = I18n.t('view.tickets.show.escalated_ticket');
  };

  render  () {
    if (this.state.members == []) {
      return true;
    }

    var currently = '';
    if (this.state.shares.length > 0) {
      currently = (
        <div className='col-md-12 share-members'>
          <h6>{I18n.t('view.tickets.show.sharing.currently_sharing')}</h6>
          <ul>
            {this.state.shares.sort().map(this.outputMember)}
          </ul>
        </div>
      );
    }

    return (
      <span>
        <button className='hk-button-sm--secondary' data-fancybox='modal' data-src='#sharingModal'>
          {I18n.t('view.tickets.show.sharing.button')} ({this.state.shares.length})
        </button>&nbsp;
        { this.state.enterprise_account ?
          <button className='hk-button-sm--primary' ahref='#' onClick={this.escalateTicket} disabled={this.state.boosted}>
            { this.state.boosted ? I18n.t('view.tickets.show.escalated_ticket') : I18n.t('view.tickets.show.escalate_ticket') }
          </button> : false
        }

        <div id='sharingModal' className='br2 bg-white shadow-outer-2'>
          <p className='f3 purple'>{I18n.t('view.tickets.show.sharing.title')}</p>
          <SharingBlurb sharing_token={this.state.sharing_token} ticket_id={this.props.ticket_id} />
          {currently}
          {this.state.updating ? <Loading /> : true}
        </div>
      </span>
    );
  }
}

TicketSharing.defaultProps = {
  app_name: '',
  sharing_token: ''
}

export default TicketSharing
