import React, { useContext } from "react"

import TemplateH12 from "./TemplateH12"
import TicketFormContext from "../../context/TicketFormContext"

const h12Node = props => {
  const context = useContext(TicketFormContext);

  return(
    <TemplateH12 last_step={3} updateMacroState={props.updateMacroState}>

      { context.macro_step == 3 &&
        <React.Fragment>
        <p>If your app is using ExpressJS, you will also want to install something like <a href="https://github.com/expressjs/timeout" target="_blank" className="hk-link">timeout</a>, which will ensure that a long running request is dropped at the dyno-level as well. Specifically, timeout raise a <code className="hk-badge--code">Response timeout</code> exception when that happens.</p>

        <p>With that in place, the compound effect is less likely to occur, but long-running actions still need to be addressed. Again, New Relic is a great tool to provide the visibility into your app to identify the long-running actions. You can then optimize them and make sure they're able to finish within a reasonable time, we suggest keeping all requests under 500ms. If they're performing any inherently long tasks, you should try to offload those to a background worker.</p>
        </React.Fragment>
      }
    </TemplateH12>
  )
}

export default h12Node
