import React from "react"
import PropTypes from "prop-types"
class TicketCount extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tickets: [],
    };
  }

  componentDidMount () {
    this.loadTickets();
  }

  loadTickets () {
    SupportApiRequest({
      url: this.props.api_path,
      success: function(tickets) {
        this.setState({
          tickets: tickets.filter(ticket => (ticket.state == 'open' || ticket.state == 'pending')),
          loading: false
        });
      }.bind(this)
    });
  }

  render () {
    if (this.state.loading == true) { return <span /> };

    return (
      <span className='bg-light-gray white f5 fw6 ph2 ml2 br-pill'>{this.state.tickets.length}</span>
    );
  }
}

TicketCount.defaultProps = {
  api_path: '/tickets'
}

export default TicketCount
