import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

const FlagCommentForm = props => {
  const DISALLOWED_TYPES = ['heading']
  const CHAR_LIMIT = props.charLimit || 1500
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [text, setText] = useState()
  const [charsRemaining, setCharsRemaining] = useState(CHAR_LIMIT)
  const [previewMode, setPreviewMode] = useState(false)

  let date = new Date(props.updatedAt)

  function render() {
    if (props.flagId && props.showForm) {
      return(
        <form onSubmit={(e) => {e.preventDefault(); props.submitComment(text)}}>
          <div className="f2 b lh-copy mb2">Thanks! Would you like to leave any specific feedback?</div>
          <div className="mv3 flex flex-row justify-between">
            <div className="flex">
              <div id="flag-comment-write" className={`${previewMode ? 'cursor-pointer purple underline-hover' : 'cursor-default'}`} onClick={() => setPreviewMode(false) }>Write</div>
              <div id="flag-comment-preview" className={`${previewMode ? 'cursor-default' : 'cursor-pointer purple underline-hover'} ml3`} onClick={() => setPreviewMode(true) }>Preview</div>
            </div>
            <div className="gray f7">Character limit {charsRemaining}/{CHAR_LIMIT}</div>
          </div>
          <>
            { !previewMode &&
              <textarea onChange={handleChange} value={text} placeholder={"Write suggestions here..."} rows="5" className="hk-input w-100" maxLength={CHAR_LIMIT}/>
            }
            { previewMode &&
              <ReactMarkdown className="mv4" disallowedTypes={DISALLOWED_TYPES} unwrapDisallowed={true}>{text + "&nbsp;"}</ReactMarkdown>
            }
          </>
          <div className="mt2">
            <div className="lh-copy gray f7 mb2">This form is solely for providing feedback on this help article. <strong>It is not an official support channel</strong>, and Heroku does not guarantee a response to submitted feedback.</div>
            <input className="hk-button-sm--primary" type="submit" defaultValue="Leave Feedback" disabled={disableSubmit}/>
            <input onClick={props.dismissSubmit} className="hk-button-sm--tertiary" type="button" defaultValue="Dismiss"/>
          </div>
        </form>
      )
    } else return null
  }

  useEffect(() => {
    if (!props.comment) {
      setText("")
    }
  }, [props.comment])

  function handleChange(e) {
    if (/\S/.test(e.target.value)) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
    const charsUsed = e.target.value.length
    setCharsRemaining(CHAR_LIMIT - charsUsed)
    setText(e.target.value)
  }

  return render()
}

export default FlagCommentForm
