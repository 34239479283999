import React from "react"

class Loading extends React.Component {
  render () {
    return (
      <svg className="w2 h2 mv4 fill-dark-gray"><use xlinkHref="#loading-28" /></svg>
    )
  }
}

export default Loading
